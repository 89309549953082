import PractitionerMainHeader from "../components/Layout/PractitionerMainHeader";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import { getPractitioner } from "../Actions/Consumer/GetPractitionerAction";
import ServiceForm from "../components/Layout/ServiceForm";
import AddServiceForm from "../components/Layout/AddServiceForm";
import { useNavigate } from "react-router-dom";
import { setSelection } from "../Actions/Practitioner/addServiceAction";
import ServiceCardPractitioner from "../components/SubComponent/ServiceCardPractitioner";
import ProfileInfo from "../components/Layout/ProfileInfo";
import ServiceTable from "../components/SubComponent/ServiceTable";
import ScheduleContainer from "../components/Layout/ScheduleContainer";
import AppointmentsHeader from "../components/Layout/AppointmentHeader";
import Inbox from "../components/Layout/Inbox";
import AddSchedule from "../components/Layout/AddSchedule";
import { getSchedules } from "../Actions/Practitioner/getSchedulesAction"
import { resetAddSchedules } from "../Actions/Practitioner/addScheduleAction";
import EditSchedule from "../components/Layout/EditSchedule"
const PractitionerDashboard = () => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const id = UserData.user.id;
  const navigate = useNavigate();
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  const selection = useSelector((state) => state.addService.selection);
  const srvData = useSelector((state) => state.addService.srvData);
  let num = 0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPractitioner(id));
    const body = {
      id: id
    };
    dispatch(getSchedules(body));
  }, [selection, dispatch]);
  const handleSide1 = () => {
    num = 0;
    dispatch(setSelection(num));
  };
  const handleSide2 = () => {
    num = 1;
    dispatch(setSelection(num));
  };
  const handleSide3 = () => {
    num = 2;
    dispatch(setSelection(num));
  };
  const handleSide4 = () => {
    num = 3;
    dispatch(setSelection(num));
  };
  const handleSide5 = () => {
    num = 4;
    dispatch(setSelection(num));
  };
  const handleSide6 = () => {
    num = 5;
    dispatch(setSelection(num));
  };
  const handleAdd = () => {
    num = 6;
    dispatch(setSelection(num));
  };
  const UpdateProfile = () => {
    num = 5;
    dispatch(setSelection(num));
  };
  const resetAdd = () => {

    dispatch(resetAddSchedules());
  };

  return UserData.user.role == 1 &&(
    <div className="w-full relative  bg-m3-sys-light-on-primary overflow-hidden text-gray-800 font-sans flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <PractitionerMainHeader></PractitionerMainHeader>
      <section className="self-stretch flex flex-row items-start justify-start max-w-full text-left text-xl text-neutral-8 font-web-secondary-underline-subtitle2-dm-sans mq1050:pl-5 mq1050:pr-5 mq1050:box-border">
        <div className="min-h-[800px] max-h-[1000px] min-w-[300px] max-w-[300px] bg-gray1-300 box-border flex flex-col items-start justify-start py-4 px-[15px]  border-silver-200 mq1050:hidden mq450:gap-[134px]">
          <div className="self-stretch flex flex-col items-center justify-center gap-[8px]">
          <div className={`self-stretch rounded-lg ${selection === 0 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/sidebar-icons.svg"
              />

              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 0 ? "font-bold" : ""
                }`}
                onClick={() => handleSide1()}
              >
                Dashboard
              </div>
            </div>
            <div className={`self-stretch rounded-lg ${selection === 1 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/sidebar-icons-1.svg"
              />
              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 1 ? "font-bold" : ""
                }`}
                onClick={() => handleSide2()}
              >
                Schedule
              </div>
            </div>
            <div className={`self-stretch rounded-lg ${selection === 2 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/sidebar-icons-2.svg"
              />
              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 2 ? "font-bold" : ""
                }`}
                onClick={() => handleSide3()}
              >
                Appointments
              </div>
            </div>
            <div className={`self-stretch rounded-lg ${selection === 3 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                alt=""
                src="/sidebar-icons-3.svg"
              />
              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 3 ? "font-bold" : ""
                }`}
                onClick={() => handleSide4()}
              >
                Services
              </div>
            </div>
            <div className={`self-stretch rounded-lg ${selection === 4 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/sidebar-icons-4.svg"
              />
              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 4 ? "font-bold" : ""
                }`}
                onClick={() => handleSide5()}
              >
                Inbox
              </div>
            </div>
            <div className={`self-stretch rounded-lg ${selection === 5 ? 'bg-whitesmoke-100' : 'bg-whitesmoke-1300'} flex flex-row items-center justify-center py-4 px-3 gap-[12px] text-neutral-10`}>
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/sidebar-icons-5.svg"
              />
              <div
                className={`flex-1 relative leading-[24px] cursor-pointer mq450:text-base mq450:leading-[19px] ${
                  selection === 5 ? "font-bold" : ""
                }`}
                onClick={() => handleSide6()}
              >
                Profile
              </div>
            </div>
          </div>
        </div>
        {practitioner === "isLoading" ? (
          <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[1000px]">
            <Loader />
          </div>
        ) : (
          practitioner.status === true && (
            <div className="h-full w-full">
              {selection === 0 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div>
                    <div className="self-stretch flex flex-row items-center justify-start max-w-full mq750:gap-[28px]">
                      <div className="flex-1 flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[8px] max-w-full">
                        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                          Dashboard
                        </h1>
                        <div className="w-[444px] relative text-sm leading-[22px] inline-block max-w-full">
                          Appointments, insights and more
                        </div>
                      </div>
                    </div>

                    <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-lg">
                      <div className="self-stretch flex flex-row items-center justify-start">
                        <h3 className="mt-10 relative text-inherit leading-[30px] font-inherit mq450:text-base mq450:leading-[24px]">
                          Appointments Today
                        </h3>
                      </div>
                      {(!practitioner.result[0].bussinessDetail ||
                        !practitioner.result[0].categoryTags ||
                        !practitioner.result[0].userImages) && (
                        <div className="self-stretch rounded-md bg-m3-sys-light-on-primary box-border flex flex-row flex-wrap items-center justify-start py-[18px] px-[19px] gap-[20px] max-w-full text-lg  border-[1px] border-solid border-gainsboro-200">
                          <img
                            className="h-5 w-5 relative overflow-hidden shrink-0"
                            loading="lazy"
                            alt=""
                            src="/icons2.svg"
                          />
                          <div className="flex-1 relative leading-[28px] inline-block min-w-[330px] max-w-full">
                            Complete your profile to reach wider audience.
                          </div>
                          <Button
                            className="h-[34px] w-[132px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              color: "#fff",
                              fontSize: "14",
                              background: "#04a3a3",
                              border: "#04a3a3 solid 1px",
                              borderRadius: "4px",
                              "&:hover": { background: "#04a3a3" },
                              width: 132,
                              height: 34,
                            }}
                            onClick={UpdateProfile}
                          >
                            Update Profile
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {selection === 3 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[8px] min-w-[475px] max-w-full mq750:min-w-full">
                      <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                        Services
                      </h1>
                      <div className="w-[444px] relative text-sm leading-[22px] inline-block max-w-full">
                        Manage all your services here
                      </div>
                    </div>
                    <Button
                      className="h-[34px] w-[177px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                      onClick={handleAdd}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "14",
                        background: "#006a6a",
                        border: "#006a6a solid 1px",
                        borderRadius: "4px",
                        "&:hover": { background: "#006a6a" },
                        width: 177,
                        height: 34,
                      }}
                    >
                      Add new Service
                    </Button>
                  </div>
                  {practitioner.result[0].service.length === 0 ? (
                    <div className="flex items-center justify-center font-bold font-inherit text-xl text-m3-black h-[400px] w-[1000px]">
                    <div>
                    <img src="no_services.svg" alt="" className="mx-auto pl-8"/>
                    <br></br>
                    No services available
                    </div>
                    </div>
                  ) : (
                    <ServiceTable services={practitioner.result[0].service} />
                  )}
                </div>
              )}
              {selection === 6 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <ServiceForm
                      data={
                        practitioner.result[0]
                          ? practitioner.result[0].categories
                          : ""
                      }
                    ></ServiceForm>
                  </div>
                </div>
              )}
              {selection === 7 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <AddServiceForm
                      data={srvData}
                      categories={
                        practitioner.result[0]
                          ? practitioner.result[0].categories
                          : ""
                      }
                    ></AddServiceForm>
                  </div>
                </div>
              )}
              {selection === 2 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                  <AppointmentsHeader services={practitioner.result[0].service}></AppointmentsHeader>
                </div>
              </div>
              )}
              {selection === 1 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                  {resetAdd()}
                  <ScheduleContainer></ScheduleContainer>
                </div>
              </div>
              )}
              {selection === 4 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                  <Inbox></Inbox>
                </div>
              </div>
              )}
              {selection === 5 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <ProfileInfo data={practitioner}></ProfileInfo>
                  </div>
                </div>
              )}
              {selection === 8 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <AddSchedule></AddSchedule>
                  </div>
                </div>
              )}
              {selection === 9 && (
                <div className="shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] flex-1 flex flex-col items-start justify-start py-14 pr-[120px] pl-14 box-border gap-[56px] text-13xl text-m3-black lg:pl-7 lg:pr-[60px] lg:box-border mq1050:max-w-full mq750:gap-[28px] mq750:pt-9 mq750:pr-[30px] mq750:pb-9 mq750:box-border min-h-[800px]">
                  <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[56px] max-w-full mq750:gap-[28px]">
                    <EditSchedule></EditSchedule>
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </section>
    </div>
  );
};

export default PractitionerDashboard;
