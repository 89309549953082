import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getConsumerBooking, getConsumerHistoryBooking, getConsumerPendingBooking, getConsumerUpcomingBooking } from "../../Actions/Consumer/ConsumerGetBookingAction";
import Loader from "../SubComponent/Loader";
import AppointmentCardConsumer from "../SubComponent/AppointmentCardConsumer";
import AppointmentCardUpcomingConsumer from "../SubComponent/AppointmentCardUpcomingConsumer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ConsumerSchedule = () => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const consumerId = UserData.user.id;
  const dispatch = useDispatch();

  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    // dispatch(getConsumerBooking(consumerId));
    dispatch(getConsumerPendingBooking(consumerId));
    dispatch(getConsumerUpcomingBooking(consumerId));
    dispatch(getConsumerHistoryBooking(consumerId));
  }, [dispatch, consumerId, updateCount]);

  const bookings = useSelector((state) => state.getConsumerBooking.getConsumerBooking);
  const historyBookings = useSelector((state) => state.getConsumerBooking.getConsumerHistoryBooking);
  const upcomingBookings = useSelector((state) => state.getConsumerBooking.getConsumerUpcomingBooking);
  const pendingBookings = useSelector((state) => state.getConsumerBooking.getConsumerPendingBooking);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };


  const handleBookingUpdate = () => {
    setUpdateCount((prev) => prev + 1);
  };

  return (
    <div className={`self-stretch flex flex-col items-start justify-start gap-[56px] max-w-full text-left text-base text-m3-black font-web-secondary-body2 mq750:gap-[28px] `}>
      <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full text-13xl">
        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Schedule
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]">
          View your upcoming and completed sessions
        </div>
      </div>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="appointment tabs">
        <Tab label="Upcoming" />
        <Tab label="Pending" />
        <Tab label="History" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0  pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {upcomingBookings == "isLoading" && <div><Loader></Loader></div>}
          {upcomingBookings != "isLoading" && upcomingBookings && upcomingBookings.length !== 0 &&
            upcomingBookings.map((booking) => (
              <AppointmentCardUpcomingConsumer
                key={booking.id}
                booking={booking}
                onBookingUpdate={handleBookingUpdate}
              />
            ))
          }
          {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0  pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {pendingBookings == "isLoading" && <div><Loader></Loader></div>}
          {pendingBookings != "isLoading" && pendingBookings && pendingBookings.length !== 0 &&
            pendingBookings.map((booking) => (
              <AppointmentCardConsumer
                key={booking.id}
                booking={booking}
              />
            ))
          }
          {historyBookings && historyBookings !== "isLoading" && historyBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0  pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {historyBookings == "isLoading" && <div><Loader></Loader></div>}
          {historyBookings != "isLoading" && historyBookings && historyBookings.length !== 0 &&
            historyBookings.map((booking) => (
              <AppointmentCardConsumer
                key={booking.id}
                booking={booking}
              />
            ))
          }
          {historyBookings && historyBookings !== "isLoading" && historyBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
    </div>
  );
};

export default ConsumerSchedule;