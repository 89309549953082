import {
  SIGNIN_FAIL,
  SIGNIN_PENDING,
  SIGNIN_SUCCESS,
  RESET_SIGNIN_STATE,
  SET_MESSAGE,
} from "../Types";
import SigninService from "../../services/Signin-service";
import { toast } from "react-toastify";

export const signIn = (body) => async (dispatch) => {
  dispatch({
    type: SIGNIN_PENDING,
    payload: { signIn: "isLoading" },
  });
  return await SigninService.signIn(body)
    .then(
      (data) => {
        console.log(data);
        if (data.data.status == true) {
          dispatch({
            type: SIGNIN_SUCCESS,
            payload: { signIn: data.data, UserData: body },
          });
        } else {
          dispatch({
            type: SIGNIN_FAIL,
            payload: { signIn: data.data, UserData: body },
          });
          if (data.data.message != "User is not confirmed.") {
            toast.error(data.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (data.data.message == "User is not confirmed.") {
            toast.info("User confirmation required", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: SIGNIN_FAIL,
          payload: { signIn: error.response.data, UserData: body },
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const resetSigninState = () => ({
  type: RESET_SIGNIN_STATE,
});
