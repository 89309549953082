import { useCallback } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SidebarLight from "../components/Layout/SidebarLight";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import ConsumerSchedule from "../components/Layout/ConsumerSchedule";
import { useDispatch, useSelector } from "react-redux";

const ConsumerProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar.sideBar); 
  const onSidebarTileContainerClick = useCallback(() => {
    navigate("/consumer-schedule-upcoming");
  }, [navigate]);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const onSidebarTileContainerClick1 = useCallback(() => {
    navigate("/consumer-inbox");
  }, [navigate]);
console.log(sideBar);
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-10 box-border gap-[40px] leading-[normal] tracking-[normal] mq750:gap-[20px]">
      <ConsumerHeader></ConsumerHeader>
      <main className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <section className="w-[1200px] flex flex-row items-start justify-center gap-[32px] max-w-full mq1050:flex-wrap mq750:gap-[16px]">
          <SidebarLight
            personOutline="/person_outline2.svg"
            eventNote="/event_note2.svg"
            forum="/forum.svg"
           
          />
          <div className="flex-1 flex flex-col items-start justify-start pt-10 px-0 pb-0 box-border min-w-[551px] max-w-full mq750:min-w-full">
          {sideBar == 1 &&
            <ConsumerSchedule></ConsumerSchedule>
          }
          {sideBar == 0 &&
           <form className="m-0 self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full mq450:gap-[16px]">
           <h1 className="m-0 relative text-13xl leading-[48px] font-bold font-web-secondary-body1 text-m3-black text-left mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
             Account details
           </h1>
           <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full">
             
           </div>
           <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
             <div className="flex-1 flex flex-row items-start justify-start min-w-[265px] max-w-full">
               <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                 <div className="relative text-xs leading-[18px] font-web-secondary-body1 text-text-secondary text-left inline-block min-w-[60px]">
                   First Name
                 </div>
                 <input
                   className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-web-secondary-body1 text-base text-m3-ref-neutral-neutral20 min-w-[245px]"
                   placeholder={UserData.user.firstName}
                   type="text"
                 />
                 <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
               </div>
               <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                 <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                   Helper text
                 </div>
               </div>
             </div>
             <div className="flex-1 flex flex-row items-start justify-start min-w-[265px] max-w-full">
               <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                 <div className="relative text-xs leading-[18px] font-web-secondary-body1 text-text-secondary text-left inline-block min-w-[59px]">
                   Last Name
                 </div>
                 <input
                   className="w-full [border:none] [outline:none] bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-web-secondary-body1 text-base text-m3-ref-neutral-neutral20 min-w-[245px]"
                   placeholder= {UserData.user.lastName}
                   type="text"
                 />
                 <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
               </div>
               <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                 <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                   Helper text
                 </div>
               </div>
             </div>
           </div>
           <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
             <div className="flex-1 flex flex-row items-start justify-start min-w-[265px] max-w-full">
               <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                 <div className="relative text-xs leading-[18px] font-web-secondary-body1 text-text-disabled text-left inline-block min-w-[30px]">
                   Email
                 </div>
                 <input
                   className="w-full  bg-[transparent] self-stretch h-6 flex flex-row items-start justify-start font-web-secondary-body1 text-base text-m3-ref-neutral-neutral20 min-w-[245px]"
                   placeholder={UserData.user.email}
                   type="text"
                   disabled
                 />
                <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
               </div>
               <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                 <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-disabled text-left">
                   Helper text
                 </div>
               </div>
             </div>
             <div className="flex-1 flex flex-col items-end justify-start gap-[32px] min-w-[265px] max-w-full mq450:gap-[16px]">
               <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                 <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full mq450:h-auto">
                   <div className="relative text-xs leading-[18px] font-web-secondary-body1 text-text-secondary text-left inline-block min-w-[70px]">
                     Date of Birth
                   </div>
                   <div className="self-stretch flex flex-row items-start justify-start max-w-full [row-gap:20px] mq450:flex-wrap">
                     <div className="h-px w-8 hidden flex-row items-center justify-start py-0 pr-2 pl-0 box-border">
                       <div className="hidden flex-row items-start justify-start">
                         <img
                           className="h-6 w-6 relative overflow-hidden shrink-0"
                           alt=""
                           src="/starfilled-1.svg"
                         />
                       </div>
                     </div>
                     <div className="h-6 w-0 relative hidden" />
                     <div className="self-stretch w-[220px] relative text-base tracking-[0.15px] leading-[24px] font-components-helper-text text-text-primary text-left hidden" />
                     <input
                       className="w-[calc(100%_-_24px)] [border:none] [outline:none] font-web-secondary-body1 text-base bg-[transparent] h-6 flex-1 relative leading-[24px] text-m3-ref-neutral-neutral20 text-left inline-block min-w-[250px] max-w-full p-0"
                       placeholder={UserData.user.dateOfBirth? UserData.user.dateOfBirth: "01-01-2000"}
                       type="text"
                     />
                     <div className="flex flex-col items-start justify-start pt-[11.5px] px-0 pb-0">
                       <div className="w-6 h-px flex flex-row items-start justify-start relative">
                         <img
                           className="h-6 w-full absolute !m-[0] top-[calc(50%_-_12px)] right-[0px] left-[0px] max-w-full overflow-hidden"
                           alt=""
                           src="/edit-calendar.svg"
                         />
                       </div>
                     </div>
                     <div className="h-0 w-6 relative hidden" />
                   </div>
                   <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-m3-ref-neutral-neutral20" />
                 </div>
                 <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                   <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                     Helper text
                   </div>
                 </div>
               </div>
               <Button
                 className="w-[185px] h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                 variant="contained"
                 sx={{
                   textTransform: "none",
                   color: "#fff",
                   fontSize: "18",
                   background: "#f28500",
                   border: "#f28500 solid 1px",
                   borderRadius: "32px",
                   "&:hover": { background: "#f28500" },
                   width: 185,
                   height: 52,
                 }}
               >
                 Save Changes
               </Button>
             </div>
           </div>
         </form>}

          </div>
        </section>
      </main>
    </div>
  );
};

export default ConsumerProfile;
