import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const LoginProtected = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const isAuthenticated = !!user;
  const practitionerRoutes = ["/practitioner", "/practitioner-profile-detailing"];
  const consumerRoutes = ["/consumer-profile", "/consumer-preferences-01", "/consumer-preferences-02"];

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (user.user.role === 1 && practitionerRoutes.includes(location.pathname)) {
    return <Outlet />;
  }

  if (user.user.role === 0 && consumerRoutes.includes(location.pathname)) {
    return <Outlet />;
  }

  return <Navigate to="/access-denied" />;
};

export default LoginProtected;