import { Button, OutlinedInput, IconButton, ToggleButtonGroup, ToggleButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import { format, parseISO } from 'date-fns';
import { setSchedule } from "../../Actions/Practitioner/getSchedulesAction";

const ScheduleContainer = () => {
  const schedule = useSelector((state) => state.getSchedules.getSchedules);
  const [disable, setDisable] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const id = UserData.user.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [toggleWeek, setToggleWeek] = useState("week1");
  const [weekDates, setWeekDates] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggleWeek(newToggle);
      updateWeekDates(newToggle);
    }
  };

  const handleAdd = () => {
    let num = 8;
    dispatch(setSelection(num));
  };
  const handleEdit = () => {
    const selected = schedule.find(scd => scd.scheduleId === selectedSchedule);
    if (selected) {
        dispatch(setSchedule(selected));
        let num = 9;
        dispatch(setSelection(num));

    }
};

  const handleChange = (event) => {
    setSelectedSchedule(event.target.value);
    updateWeekDates(toggleWeek, event.target.value);
  };

  const handleDaySelect = (date) => {
    setSelectedDay(date);

    const selectedScheduleData = schedule.find((sch) => sch.scheduleId === selectedSchedule);
    if (selectedScheduleData) {
      const selectedDaySlots = selectedScheduleData.updateScheduleSlotRequests.find(
        (slot) => slot.slotDate === format(date, "yyyy-MM-dd")
      );

      setSelectedDaySlots(selectedDaySlots ? selectedDaySlots.slotTimes : []);
    }
  };



const updateWeekDates = (week, scheduleId) => {
  if (!scheduleId) return;
  
  const selectedSchedule = schedule.find(scd => scd.scheduleId === scheduleId);
  const startDate = new Date(selectedSchedule.scheduleStartDate.replace('Z', '')); 
  const weekDates = [];
  
  for (let i = 0; i < 14; i++) {
    const date = new Date(startDate); 
    date.setDate(startDate.getDate() + i ) ;
    
    if ((week === "week1" && i < 7) || (week === "week2" && i >= 7)) {
      weekDates.push(date);
    }
  }
  
  setWeekDates(weekDates);
};

  useEffect(() => {
    setSelectedSchedule(schedule!= "isLoading" && schedule[0] ? schedule[0].scheduleId : null);
    if (selectedSchedule) {
      updateWeekDates(toggleWeek, selectedSchedule);
    }
  }, [ schedule]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const timeDiff = end.getTime() - start.getTime();
    const durationMinutes = timeDiff / 1000 / 60;
    return `${durationMinutes} Minutes`;
  };
  return (
    
    <div className="self-stretch flex flex-col items-start justify-start gap-[56px] max-w-[1200px] text-left text-13xl text-m3-black font-web-secondary-body2 mq675:gap-[28px]">
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[350px] max-w-full mq750:gap-[28px]">
        <div className="flex-1 flex flex-col items-start justify-start py-0 pl-0 box-border gap-[8px] min-w-[475px] max-w-full mq750:min-w-full">
          <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Weekly Schedule
          </h1>
          <div className="self-stretch relative text-sm leading-[22px]">
            {`Check your schedule and slots here. `}
          </div>
        </div>
        <div className="w-[189px] flex flex-col items-start justify-start px-0 pb-0 box-border">
          <Button
            className="self-stretch h-[34px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "14",
              background: "#006a6a",
              borderRadius: "4px",
              "&:hover": { background: "#006a6a" },
              height: 34,
            }}
            disabled = {schedule.length ===2? true : false}
            onClick={handleAdd}
          >
            Add new Schedule
          </Button>
        </div>
      </div>
      {schedule.status === false ? (
        <div className="flex items-center justify-center font-bold font-inherit text-xl text-m3-black h-[400px] w-[1000px]">
          <div>
            <img src="noSchedule.svg" alt="" className="mx-auto pl-56" />
            <br className="pt-4"></br>
            Nothing on the schedule, Let's fill it with your available hours!
          </div>
        </div>
      ) : (selectedSchedule &&
        <div className="min-h-[600px] max-h-auto self-stretch flex flex-col justify-start gap-[50px] max-w-full min-w-full text-left text-gray-800 font-sans mq750:gap-[20px]">
         <div className="min-w-[1000px] max-w-[1000px] flex justify-between">
          <div className="min-w-[400px] max-w-[400px] flex justify-between">
            <FormControl
              sx={{
                minWidth: 400,
                '& .MuiInputLabel-root': { width: '100%' }, 
              }}
              className="h-[53px] flex-1 font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 max-w-full"
            >
              <InputLabel>Select Schedule</InputLabel>
              <Select
                value={selectedSchedule}
                onChange={handleChange}
                input={<OutlinedInput label="Choose Schedule" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      width: 250,
                    },
                  },
                }}
              >
                {schedule === "isLoading" ? (
                  <MenuItem>Loading...</MenuItem>
                ) : (
                  schedule.length > 0 ? (
                    schedule.map((scd) => (
                      <MenuItem key={scd.scheduleId} value={scd.scheduleId}>
                        {format(new Date(scd.scheduleStartDate.replace('Z', '')),"d MMMM yyyy")} - {format(new Date(scd.scheduleEndDate.replace('Z', '')),"d MMMM yyyy")}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No schedules available</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <IconButton
              aria-label="edit schedule"
              sx={{ marginLeft: 2 }}
              disabled = {selectedSchedule === ""}
              onClick={handleEdit}
            >
              <img src="/editSchedule.svg" alt="" />
            </IconButton>
            </div>
            <div className="min-w-[400px] max-w-[400px] flex pl-6">
            <ToggleButtonGroup
              className="min-w-[400px]"
              value={toggleWeek}
              exclusive
              onChange={handleToggle}
              aria-label="week toggle"
            >
              <ToggleButton
                value="week1"
                aria-label="week 1"
                className="min-w-[200px] rounded-full"
              >
                Week 1
              </ToggleButton>
              <ToggleButton
                value="week2"
                aria-label="week 2"
                className="min-w-[200px] rounded-full"
              >
                Week 2
              </ToggleButton>
            </ToggleButtonGroup>
            </div>
          </div>
          <div className="w-full flex flex-wrap gap-[10px] mt-4">
            {weekDates.map((date, index) => (
              <div key={index} className="flex flex-col items-center">
                <Button
                  variant="contained"
                  sx={{
                    minWidth: "140px",
                    borderRadius: "50px",
                    boxShadow: "none",
                    backgroundColor:
                      selectedDay?.getTime() === date.getTime()
                        ? "#006CF0"
                        : "#EAF2FF",
                    color:
                      selectedDay?.getTime() === date.getTime() ? "white" : "black",
                    "&:hover": {
                      backgroundColor:
                        selectedDay?.getTime() === date.getTime()
                          ? "#006CF0"
                          : "#EAF2FF",
                      color:
                        selectedDay?.getTime() === date.getTime()
                          ? "white"
                          : "black",
                    },
                  }}
                  onClick={() => handleDaySelect(date)}
                >
                  {format(date, "EEEE")}
                  <br />
                  {format(date, "MMMM d")}
                </Button>
              </div>
            ))}
            <h5 className="w-full flex items-start mt-4 font-semibold">Available slots</h5>
            {selectedDay && (
            <div className="flex flex-wrap items-start justify-start gap-6">
              {selectedDaySlots.map((slot, index) => (
                <div key={index} className="w-[291px] rounded-xl bg-neutral-1 shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)]  flex flex-col items-center justify-start p-10 gap-[24px]">
                  <div className="self-stretch flex flex-row items-center justify-between gap-[20px]">
                    <div className="overflow-hidden flex flex-row items-center justify-start">
                      <b className="relative leading-[28px] inline-block min-w-[74px]">
                      Slot {index + 1}
                      </b>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-end justify-start gap-[8px] text-right text-base text-m3-black">
                    <div className="self-stretch flex flex-row items-center justify-between gap-[20px]">
                      <div className="flex flex-col items-start justify-center text-left">
                        <div className="relative leading-[24px] font-semibold inline-block min-w-[77px] whitespace-nowrap">
                        {new Date(`2000-01-01T${slot.slotStart}`).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
                        </div>
                      </div>
                      <div className="relative text-xs leading-[18px] inline-block min-w-[16px]">
                        TO
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <div className="relative leading-[24px] font-semibold inline-block min-w-[77px] whitespace-nowrap">
                        {new Date(`2000-01-01T${slot.slotEnd}`).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                      <button className="cursor-pointer [border:none] py-1.5 px-2 rounded flex flex-row items-center justify-center hover:bg-gainsboro-300">
                        <div className="relative text-sm leading-[22px] font-web-secondary-body2 text-daybreak-blue-6 text-center inline-block min-w-[37px]">
                          {slot.bookingStatus}
                        </div>
                      </button>
                      <button className="cursor-pointer [border:none] py-1.5 px-2 ml-14 bg-silver-100 rounded-lg flex flex-row items-center justify-center whitespace-nowrap hover:bg-gray1-800">
                        <div className="relative text-sm leading-[22px] font-web-secondary-body2 text-color-gray-100 text-center inline-block min-w-[74px]">
                          {calculateDuration(slot.slotStart, slot.slotEnd)}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleContainer;