import { combineReducers } from 'redux';
import confirm_signup_reducer from './Auth/ConfirmSignupReducer';
import signupReducer from './Auth/SignupReducer';
import Messages from './Message';
import resendotp_reducer from "./Auth/ResendOtpReducer";
import signinReducer from "./Auth/SigninReducer";
import forgotPasswordReducer from "./Auth/ForgotPasswordReducer";
import confirmForgotPasswordReducer from "./Auth/ConfirmForgotPasswordReducer"
import getCategoriesReducer from "./Categories/GetCategoriesReducer"
import change_selection_reducer from './Consumer/ConsumerPreferenceReducer';
import profile_detailing_reducer from './Practitioner/ProfileDetailingReducer';
import get_featured_practitioners from './Consumer/GetPractitionerReducer';
import get_popular_services from './Consumer/GetPopularServicesReducer';
import get_practitioner from './Consumer/GetPractitionerIdReducer';
import get_service_practitioner from './Consumer/GetServiceByPractitionerReducer';
import addServiceReducer from './Practitioner/AddServiceReducer';
import updateServiceReducer from './Practitioner/UpdateServiceReducer';
import updateProfileReducer from './Practitioner/UpdateProfileReducer';
import getSchedulesReducer from './Practitioner/getScheduleReducer';
import addScheduleReducer from './Practitioner/AddScheduleReducer';
import updateScheduleReducer from './Practitioner/UpdateScheduleReducer';
import addBookingReducer from './Consumer/AddBookingReducer';
import getBookingReducer from './Practitioner/GetBookingReducer';
import cancelBookingReducer from './Practitioner/PractitionerBookingReducer';
import getElasticPractitionersReducer from './Consumer/elasticPractitionersReducer';
import getElasticServicesReducer from './Consumer/elasticServicesReducer';
import getConsumerBookingReducer from './Consumer/GetConsumerBooking';
import setSidebarReducer from './Consumer/SetSidebarTabReducer';
import cancelBookingReducerConsumer from './Consumer/ConsumerBookingReducer';


export default combineReducers({
  signup: signupReducer,
  messages: Messages,
  confirm_signup: confirm_signup_reducer,
  resendOtp: resendotp_reducer,
  signin: signinReducer,
  forgotPassword: forgotPasswordReducer,
  confirmForgotPassword: confirmForgotPasswordReducer,
  getCategories: getCategoriesReducer,
  selected: change_selection_reducer,
  profileDetailing: profile_detailing_reducer,
  getPractitioners: get_featured_practitioners,
  getServices: get_popular_services,
  practitioner: get_practitioner,
  getServiceByPractitioner: get_service_practitioner,
  addService: addServiceReducer,
  updateService: updateServiceReducer,
  updateProfile: updateProfileReducer,
  getSchedules: getSchedulesReducer,
  addSchedule: addScheduleReducer,
  updateSchedule: updateScheduleReducer,
  addBooking: addBookingReducer,
  getBooking: getBookingReducer,
  cancelBooking: cancelBookingReducer,
  elasticPractitioners: getElasticPractitionersReducer,
  elasticServices: getElasticServicesReducer,
  getConsumerBooking: getConsumerBookingReducer,
  sideBar: setSidebarReducer,
  cancelBookingConsumer: cancelBookingReducerConsumer,

});