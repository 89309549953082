import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class AddBooking {
    async createConsumerBooking(body) {
        interceptor(); 
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.post(API_URL + "Booking/BookingPractitionerScheuldeSlot", body, {
            headers: {
                ...authHeader(),
                TimeZoneOffSet: timeZoneOffset,
            },
        });
    }
}

export default new AddBooking();

