import axios from 'axios';
import SigninService from './Signin-service';


export const interceptor = () => {
    return (
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if(error.response.status === 401){
        const user = JSON.parse(localStorage.getItem("user"));
        const body = {
          "id": user.user.id,
          "refreshToken": user.refreshToken,
        };
        localStorage.removeItem("user");
        return SigninService.refreshToken(body)
          .then((response) => {
            if (response.data) {
              localStorage.setItem("user", JSON.stringify(response.data.result[0]));
              setTimeout(()=>{
                window.location.reload();
              },1000)
            }
          }).catch((error => {
            localStorage.clear();
            console.log(error);
            return error;
          }));
      }
    })
    )
}