import React from 'react';
import {
  Menu,
  MenuItem,
  Button,
  TextField
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const PractitionerHeader = () => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const NavigateToHome = () => {
    navigate('/');
    localStorage.clear();
  }
  const NavigateToDashboard = () => {
    navigate('/practitioner');
  }
  const navigate = useNavigate();
  const NavigateToSignup = () => {
    navigate('/practitioner-sign-up')
  }
  const NavigateToSignin = () => {
    navigate('/practitioner-sign-in')
  }
  const handleLogoClick = () => {
    navigate('/');
  };
  return (
    <header className="self-stretch h-[108px] bg-ghostwhite-100 flex flex-row items-start justify-center py-6 px-5 box-border gap-[732.2px] top-[0] z-[99] sticky mq450:gap-[92px] mq750:gap-[183px] mq1225:gap-[366px]">
      <div className="w-[251.8px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border">
        <div className="self-stretch flex flex-row items-end justify-start gap-[13.2px] cursor-pointer" onClick={handleLogoClick}>
          <img
            className="h-[50px] w-[50.6px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/the-healers-logo.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[13px]">
            <img
              className="self-stretch h-[23.9px] relative max-w-full overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/the-healers.svg"
            />
          </div>
        </div>
      </div>
      {(UserData && UserData.user && UserData.user.role === 1) ?
        <div className="self-stretch w-[260px] flex flex-row items-start justify-start gap-[16px]">
          <div className="w-[] flex flex-col items-start justify-start pt-[11px] px-0 pb-0 box-border">
          </div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >

            {UserData.user.profileImage ? (
              <img
                className="ml-2"
                width="41px"
                height="41px"
                src={UserData.user.profileImage}
                alt="Avatar"
              />
            ) : (
              <img
                className="ml-2"
                width="41px"
                height="41px"
                src="/placeholder_profileImage.png"
                alt="Avatar"
              />
            )}
            <span
              style={{ textTransform: "none" }}
              className="ml-2 text-base text-black font-inherit"
            >
              {UserData.user.fullName}
            </span>
            <img
              className="ml-2"
              width="24px"
              height="24px"
              src="/keyboard-arrow-down.svg"
              alt="Dropdown"
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={NavigateToDashboard}>
              Dashboard
            </MenuItem>
            <MenuItem onClick={NavigateToHome}>Logout</MenuItem>
          </Menu>
        </div>
        :
        <div className="self-stretch w-64 flex flex-row items-start justify-start gap-[16px]">
          <div className="w-[79px] flex flex-col items-start justify-start pt-[11px] px-0 pb-0 box-border">
            <Button
              className="self-stretch h-[38px]"
              disableElevation={true}
              variant="text"
              sx={{
                textTransform: 'none',
                color: '#006a6a',
                fontSize: '18',
                borderRadius: '4px',
                height: 38,
              }}
              onClick={NavigateToSignin}
            >
              Login
            </Button>
          </div>
          <Button
            className="self-stretch flex-1 [filter:drop-shadow(0px_2px_0px_rgba(0,_0,_0,_0.02))]"
            variant="outlined"
            sx={{
              textTransform: 'none',
              color: '#006a6a',
              fontSize: '18',
              borderColor: '#006a6a',
              borderRadius: '8px',
              '&:hover': { borderColor: '#006a6a' },
            }}
            onClick={NavigateToSignup}
          >
            Sign Up Now
          </Button>
        </div>
      }
    </header>
  );
};

export default PractitionerHeader;