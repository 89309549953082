import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";

const Inbox = () => {
  return (
    <div className="w-full relative shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] bg-neutral-1 overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <main className="self-stretch flex flex-row flex-wrap items-start justify-center [row-gap:20px] max-w-full text-left text-xl text-black font-web-primary-h5-primary-dm-sans">
        <div className="w-[349px] shadow-[1px_0px_0px_rgba(0,_0,_0,_0.08)] bg-neutral-1 overflow-hidden shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-[328px] box-border gap-[32px] max-w-full font-heading-h5 mq1050:pb-[213px] mq1050:box-border mq450:gap-[16px] mq450:pb-[138px] mq450:box-border">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-row items-center justify-start p-6">
              <div className="flex flex-row items-center justify-start gap-[10px]">
                <div className="flex flex-row items-center justify-start">
                  <h3 className="m-0 relative text-inherit leading-[150%] font-semibold font-inherit inline-block min-w-[99px] mq450:text-base mq450:leading-[24px]">
                    Messages
                  </h3>
                </div>
                <div className="rounded-3xl bg-gray-gray200 flex flex-col items-start justify-start py-0.5 px-2 text-xs">
                  <div className="relative leading-[150%] font-semibold inline-block min-w-[14px]">
                    12
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-px relative bg-action-selected" />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start text-sm font-web-primary-h5-primary-dm-sans">
            <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[8px]">
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-start p-3 gap-[16px]">
                <img
                  className="h-12 w-12 relative rounded-xl overflow-hidden shrink-0 object-cover"
                  loading="lazy"
                  alt=""
                  src="/frame-10@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                      <div className="flex-1 relative leading-[22px] font-semibold">
                        Elmer Laverty
                      </div>
                      <div className="relative leading-[22px] font-semibold text-gray1-900 inline-block min-w-[26px]">
                        12m
                      </div>
                    </div>
                    <div className="self-stretch relative leading-[22px] text-gray-300">
                      Haha oh man 🔥
                    </div>
                  </div>
                  <div className="w-[167px] hidden flex-row items-start justify-start gap-[8px] text-xs text-orange-orange600 font-heading-h5">
                    <div className="h-[22px] rounded-xl bg-orange-orange200 flex flex-row items-start justify-start py-0.5 px-2 box-border">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Question
                      </div>
                    </div>
                    <div className="h-[22px] flex-1 rounded-xl bg-green-green200 flex flex-row items-start justify-start py-0.5 px-2 box-border whitespace-nowrap text-green-green600">
                      <div className="self-stretch flex-1 relative leading-[150%] font-semibold">
                        Help wanted
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch rounded-xl bg-mediumslateblue-200 overflow-hidden flex flex-row items-start justify-start p-3 gap-[16px]">
                <img
                  className="h-12 w-12 relative rounded-xl overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/frame-10-1@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                      <div className="flex-1 relative leading-[22px] font-semibold">
                        Florencio Dorrance
                      </div>
                      <div className="relative leading-[22px] font-semibold text-gray1-900 inline-block min-w-[30px]">
                        24m
                      </div>
                    </div>
                    <div className="self-stretch relative leading-[22px] text-gray-300">
                      woohoooo
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-start p-3 gap-[16px]">
                <img
                  className="h-12 w-12 relative rounded-xl overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/frame-10-3@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                      <div className="flex-1 relative leading-[22px] font-semibold">
                        Titus Kitamura
                      </div>
                      <div className="relative leading-[22px] font-semibold text-gray1-900 inline-block min-w-[18px]">
                        5h
                      </div>
                    </div>
                    <div className="self-stretch relative leading-[22px] text-gray-300">
                      omg, this is amazing
                    </div>
                  </div>
                  <div className="w-[167px] hidden flex-row items-start justify-start gap-[8px] text-xs text-orange-orange600 font-heading-h5">
                    <div className="h-[22px] rounded-xl bg-orange-orange200 flex flex-row items-start justify-start py-0.5 px-2 box-border">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Question
                      </div>
                    </div>
                    <div className="h-[22px] flex-1 rounded-xl box-border flex flex-row items-start justify-start py-0.5 px-[7px] whitespace-nowrap text-gray-gray600 border-[1px] border-solid border-gray-gray400">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Some content
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-start p-3 gap-[16px]">
                <img
                  className="h-12 w-12 relative rounded-xl overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/frame-10-4@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                      <div className="flex-1 relative leading-[22px] font-semibold">
                        Geoffrey Mott
                      </div>
                      <div className="relative leading-[22px] font-semibold text-gray1-900 inline-block min-w-[17px]">
                        2d
                      </div>
                    </div>
                    <div className="self-stretch relative leading-[22px] text-gray-300">
                      aww 😍
                    </div>
                  </div>
                  <div className="w-[167px] hidden flex-row items-start justify-start gap-[8px] text-xs text-orange-orange600 font-heading-h5">
                    <div className="h-[22px] rounded-xl bg-orange-orange200 hidden flex-row items-start justify-start py-0.5 px-2 box-border">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Request
                      </div>
                    </div>
                    <div className="h-[22px] rounded-xl bg-green-green200 flex flex-row items-start justify-start py-0.5 px-2 box-border text-green-green600">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Request
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch overflow-hidden flex flex-row items-start justify-start p-3 gap-[16px]">
                <img
                  className="h-12 w-12 relative rounded-xl overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/frame-10-5@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
                      <div className="flex-1 relative leading-[22px] font-semibold">
                        Alfonzo Schuessler
                      </div>
                      <div className="relative leading-[22px] font-semibold text-gray1-900 inline-block min-w-[18px]">
                        1m
                      </div>
                    </div>
                    <div className="self-stretch relative leading-[22px] text-gray-300">
                      perfect!
                    </div>
                  </div>
                  <div className="w-[167px] hidden flex-row items-start justify-start gap-[8px] text-xs text-gray-gray600 font-heading-h5">
                    <div className="h-[22px] rounded-xl box-border hidden flex-row items-start justify-start py-0.5 px-2 whitespace-nowrap border-[1px] border-solid border-gray-gray400">
                      <div className="self-stretch relative leading-[150%] font-semibold whitespace-nowrap">
                        Some content
                      </div>
                    </div>
                    <div className="h-[22px] rounded-xl box-border flex flex-row items-start justify-start py-0.5 px-[7px] whitespace-nowrap border-[1px] border-solid border-gray-gray400">
                      <div className="self-stretch relative leading-[150%] font-semibold">
                        Follow up
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[56px] min-w-[514px] max-w-full mq750:min-w-full mq450:gap-[28px]">
          <div className="self-stretch flex flex-col items-start justify-start max-w-full">
            <div className="self-stretch flex flex-row items-center justify-between py-[18px] px-6 gap-[20px] mq450:flex-wrap">
              <div className="flex flex-row items-center justify-start gap-[16px]">
                <img
                  className="h-10 w-10 relative rounded-3xs overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/frame-10-6@2x.png"
                />
                <div className="flex flex-col items-start justify-start">
                  <h3 className="m-0 relative text-inherit leading-[30px] font-bold font-inherit mq450:text-base mq450:leading-[24px]">
                    Florencio Dorrance
                  </h3>
                </div>
              </div>
              <button className="cursor-pointer [border:none] py-2.5 px-4 bg-primary-10 rounded-lg flex flex-row items-center justify-start">
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0"
                  alt=""
                  src="/add-circle-outline.svg"
                />
              </button>
            </div>
            <div className="w-[660px] h-px relative bg-action-selected max-w-full" />
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-6 box-border max-w-full">
            <TextField
              className="[border:none] bg-[transparent] h-12 flex-1 font-web-primary-h5-primary-dm-sans text-sm text-gray-300 max-w-full"
              placeholder="Type a message"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <img width="24px" height="24px" src="/iconsax.svg" />
                ),
              }}
              sx={{
                "& fieldset": { borderColor: "#e2e8f0" },
                "& .MuiInputBase-root": {
                  height: "48px",
                  backgroundColor: "#fff",
                  paddingRight: "20px",
                  borderRadius: "12px",
                  fontSize: "14px",
                },
                "& .MuiInputBase-input": { color: "rgba(0, 0, 0, 0.4)" },
              }}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Inbox;
