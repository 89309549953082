
import {PROFILE_DETAILING_FAIL,PROFILE_DETAILING_SUCCESS,PROFILE_DETAILING_PENDING,RESET_PROFILE_DETAILING} from "../../Actions/Types";
    const initialState = {
        profileDetailing: "",
    };
    const profile_detailing_reducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case PROFILE_DETAILING_SUCCESS:
          return {
            ...state,
            profileDetailing: payload.profileDetailing,
          };
        case PROFILE_DETAILING_PENDING:
          return {
            ...state,
            profileDetailing: payload.profileDetailing,
          };
        case PROFILE_DETAILING_FAIL:
        return {
          ...state,
        };
        case RESET_PROFILE_DETAILING:
      return {
        ...state,
        profileDetailing: initialState.profileDetailing, 
      };
        default:
          return state;
      }
    };
    export default profile_detailing_reducer;