import { Menu, MenuItem, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ConsumerHeader = () => {
  const navigate = useNavigate();
  const UserData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const NavigateToHome = () => {
    navigate("/consumer-landing-page");
    localStorage.clear();
  };
  const NavigateToProfile = () => {
    navigate("/consumer-profile");
  };
  const handleLogoClick = () => {
    navigate("/consumer-landing-page");
  };
  return (
    <header className="self-stretch flex w-full justify-center shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] bg-neutral-1 pl-[60px] flex flex-row items-center py-6 px-6 sticky top-[0] z-[99] border-[1px] border-solid border-gainsboro-100 lg:gap-[362px] mq450:gap-[90px] mq750:gap-[181px]">
      <div className="selfstreach flex flex-row  w-[1400px] justify-between px-[20px]">
        <div
          className="flex flex-col items-start justify-start px-0 pb-0 box-border cursor-pointer"
          onClick={handleLogoClick}
        >
          <div className="flex flex-row items-end justify-start gap-[12px]">
            <img
              className="h-[47px] w-[47.4px] relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/the-healers-logo2.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[12.2px]">
              <img
                className="self-stretch h-[22.5px] relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/the-healers3.svg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end gap-4">
          <img
            className="h-8 w-8 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/notifications-none.svg"
          />
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="flex items-center"
          >
            {(UserData && UserData.user.role === 0 && UserData.user.profileImage) ? (
              <img
                className="ml-2"
                width="41px"
                height="41px"
                src={UserData.user.profileImage}
                alt="Avatar"
              />
            ) : (
              <img
                className="ml-2"
                width="41px"
                height="41px"
                src="/Rectangle 39977.svg"
                alt="Avatar"
              />
            )}
            <span
              style={{ textTransform: "none" }}
              className="ml-2 text-base text-black font-inherit"
            >
              {(UserData && UserData.user && UserData.user.role === 0) ? UserData.user.fullName : "Guest"}
            </span>
            <img
              className="ml-2"
              width="24px"
              height="24px"
              src="/keyboard-arrow-down.svg"
              alt="Dropdown"
            />
          </Button>
          {UserData && UserData.user.role === 0 && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={NavigateToHome}>Logout</MenuItem>
              <MenuItem onClick={NavigateToProfile}>User Information</MenuItem>
            </Menu>
          )}
        </div>
      </div>
    </header>

  );
};

export default ConsumerHeader;
