import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar, resetSidebar } from "../../Actions/Consumer/SetSidebarTabAction";

const SidebarLight = ({
  className = "",
  personOutline,
  eventNote,
  forum,
  personOutlineIconMinWidth,
  sidebarTileBackgroundColor,
  tasksColor,
  sidebarTileBackgroundColor1,
  tasksColor1,
  sidebarTileBackgroundColor2,
  tasksColor2,
}) => {
  const sidebarLightStyle = useMemo(() => {
    return {
      minWidth: personOutlineIconMinWidth,
    };
  }, [personOutlineIconMinWidth]);
  
  const sidebarTileStyle = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor,
    };
  }, [sidebarTileBackgroundColor]);

  const tasksStyle = useMemo(() => {
    return {
      color: tasksColor,
    };
  }, [tasksColor]);

  const sidebarTile1Style = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor1,
    };
  }, [sidebarTileBackgroundColor1]);

  const tasks1Style = useMemo(() => {
    return {
      color: tasksColor1,
    };
  }, [tasksColor1]);

  const sidebarTile2Style = useMemo(() => {
    return {
      backgroundColor: sidebarTileBackgroundColor2,
    };
  }, [sidebarTileBackgroundColor2]);

  const tasks2Style = useMemo(() => {
    return {
      color: tasksColor2,
    };
  }, [tasksColor2]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar.sideBar); 

  const handleProfileClick = () => {
    let num = 0;
    dispatch(setSidebar(num));
  };
  const handleScheduleClick = () => {
    let num =1;
    dispatch(setSidebar(num));
  };

  return (
    <div
      className={`w-[322px] rounded-lg bg-whitesmoke-400 box-border overflow-hidden shrink-0 flex flex-col items-start justify-start py-[53px] px-[13px] gap-[40px] min-w-[322px] max-w-full text-left text-2xl text-darkslategray-200 font-web-secondary-body1 mq1050:flex-1 mq1050:pt-[34px] mq1050:pb-[34px] mq1050:box-border mq450:gap-[20px] mq450:pt-[22px] mq450:pb-[22px] mq450:box-border ${className}`}
      style={sidebarLightStyle}
    >
      <div className="self-stretch h-80 flex flex-row items-start justify-start py-0 pr-[17px] pl-[15px] box-border">
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[24px] mq1050:flex-1">
          <div className="self-stretch flex-1 overflow-hidden flex flex-row items-end justify-center py-3.5 px-5 relative">
            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
              <img
                className="absolute  top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-[50%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/mask-shape@2x.png"
              />
              
            </div>
            <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center z-[2]">
              
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[57px] pl-[57.5px] mq450:pl-5 mq450:pr-5 mq450:box-border">
            <h3 className="m-0 relative text-inherit leading-[36px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px]">
              
            </h3>
          </div>
        </div>
      </div>
      <div className="w-[290px] flex flex-col items-center justify-center gap-[8px] text-base text-neutral-7">
        <div
          className={`self-stretch rounded-13xl flex flex-row items-start justify-between py-4 pr-[160px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border ${
            sideBar === 0 ? "bg-darkorange text-white" : ""
          }`}
          style={sidebarTileStyle}
          onClick={handleProfileClick}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              alt=""
              src={sideBar === 0? "/person-outline.svg" :personOutline}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[49px] cursor-pointer"
            style={tasksStyle}
          >
            Profile
          </div>
        </div>
        <div
          className={`self-stretch rounded-13xl flex flex-row items-start justify-between py-4 pr-[140px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border ${
            sideBar === 1 ? "bg-darkorange text-white" : ""
          }`}
          onClick={handleScheduleClick}
          style={sidebarTile1Style}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src={sideBar === 1? "/event_note2.svg" :"/event-note.svg"}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[72px]"
            style={tasks1Style}
          >
            Schedule
          </div>
        </div>
        <div
          className="self-stretch rounded-13xl flex flex-row items-start justify-between py-4 pr-[169px] pl-6 gap-[20px] cursor-pointer mq450:pr-5 mq450:box-border"

          style={sidebarTile2Style}
        >
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src={forum}
            />
          </div>
          <div
            className="relative leading-[24px] font-semibold inline-block min-w-[43px]"
            style={tasks2Style}
          >
            Inbox
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center pt-2 px-6 pb-[7.5px] min-w-[43px]">
          <img
            className="h-[0.5px] flex-1 relative max-w-full overflow-hidden"
            loading="lazy"
            alt=""
            src="/divider2.svg"
          />
        </div>
        <div className="self-stretch rounded-13xl flex flex-row items-start justify-between py-4 pr-[148px] pl-6 gap-[20px] mq450:pr-5 mq450:box-border">
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/settings.svg"
            />
          </div>
          <div className="relative leading-[24px] font-semibold inline-block min-w-[64px]">
            Settings
          </div>
        </div>
        <div className="rounded-13xl flex flex-row items-start justify-start py-4 pr-[85px] pl-6 gap-[30px] mq450:gap-[15px] mq450:pr-5 mq450:box-border">
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/help-outline.svg"
            />
          </div>
          <div className="relative leading-[24px] font-semibold">
            Help and Support
          </div>
        </div>
        <div className="rounded-13xl flex flex-row items-start justify-start py-4 pr-[105px] pl-6 gap-[30px] mq450:gap-[15px] mq450:pr-5 mq450:box-border">
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/privacy-tip.svg"
            />
          </div>
          <div className="relative leading-[24px] font-semibold inline-block min-w-[107px]">
            Privacy Policy
          </div>
        </div>
        <div className="self-stretch rounded-13xl flex flex-row items-start justify-between py-4 pr-[159px] pl-6 gap-[20px] mq450:pr-5 mq450:box-border">
          <div className="bg-hitbox-1000 overflow-hidden flex flex-col items-center justify-center">
            <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logout.svg"
            />
          </div>
          <div className="relative leading-[24px] font-semibold inline-block min-w-[53px]">
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

SidebarLight.propTypes = {
  className: PropTypes.string,
  personOutline: PropTypes.string,
  eventNote: PropTypes.string,
  forum: PropTypes.string,

  /** Style props */
  personOutlineIconMinWidth: PropTypes.any,
  sidebarTileBackgroundColor: PropTypes.any,
  tasksColor: PropTypes.any,
  sidebarTileBackgroundColor1: PropTypes.any,
  tasksColor1: PropTypes.any,
  sidebarTileBackgroundColor2: PropTypes.any,
  tasksColor2: PropTypes.any,

  /** Action props */
  onSidebarTileContainer1Click: PropTypes.func,
  onSidebarTileContainer2Click: PropTypes.func,
};

export default SidebarLight;
