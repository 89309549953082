import {UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_PENDING, RESET_UPDATE_PROFILE, SET_MESSAGE} from "../Types"
import updateProfileService from "../../services/updateProfileService";
import { toast } from "react-toastify"


export const updateProfile = (body) => async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_PENDING,
      payload: { updateProfile : "isLoading" },
    });
    return await updateProfileService.updateProfile(body)
      .then(
        (data) => {
          console.log(data);
          if(data.status == 200)
          {
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: { updateProfile: data.data },
              })
            }
            else
            { dispatch({
                type: UPDATE_PROFILE_FAIL,
              });
              toast.error(data.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          return Promise.resolve();
        },
        (error) => {
          console.log("error ", error);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch({
            type: UPDATE_PROFILE_FAIL,
          });
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
          return Promise.reject();
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  export const resetUpdateProfile = () => ({
    type: RESET_UPDATE_PROFILE,
  });

