import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SubComponent/Loader";


const AppointmentCardConsumer = ({ booking }) => {
  const {
    fullName,
    serviceName,
    bookingStartTime,
    bookingEndTime,
    bookingType,
  } = booking;
  const dispatch = useDispatch();
  const BookingId = booking.id;
  
  const statusColorMap = {
    0: "bg-red-600",    
    1: "bg-darkorange", 
    2: "bg-red-600",    
    3: "bg-blue-600",   
    4: "bg-red-600",    
    5: "bg-red-600",   
    6: "bg-red-600",    
  };
  const bgColorClass = statusColorMap[booking.bookingStatus] || "bg-gray-300";
  const formattedStartDate = new Date(bookingStartTime).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  });
  const formattedStartTime = new Date(bookingStartTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const formattedEndTime = new Date(bookingEndTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  });
  const startTime = new Date(bookingStartTime);
  const endTime = new Date(bookingEndTime);
  const durationInMilliseconds = endTime - startTime;
  const durationInMinutes = durationInMilliseconds / (1000 * 60);
  

  return (
    <div className="w-[350px] rounded-xl bg-color-gray-10 box-border flex flex-col items-end justify-center p-6 gap-[16px] min-w-[350px] max-w-[350px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
        <div className="flex flex-row items-start justify-start gap-[6px]">
            <img
              className="w-6 relative h-6"
              alt="Clock"
              src="/vuesaxlinearclock-1.svg"
            />
            <div className="relative leading-[22px] font-semibold text-lg">{formattedStartTime}</div>
            <div className="relative leading-[22px] font-semibold text-lg">{`TO `}</div>
            <div className="relative leading-[22px] font-semibold text-lg">{formattedEndTime}</div>
          </div>

          <div className="flex-1 relative leading-[24px] text-sm">
            {formattedStartDate}
          </div>
    
        <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-color-gray-10 flex flex-row items-center justify-between pt-0 px-0 pb-4">
        <img
            className="w-12 relative rounded-[50%] h-12 object-cover"
            alt="User"
            src={booking.userImages ? booking.userImages.profileImage ? booking.userImages.profileImage : "/Placeholder-profile.png" : "/Placeholder-profile.png"}
          />
          <div className="flex-1 flex flex-row items-start justify-center gap-[8px] pl-4">
            <div className="flex-1 flex flex-col items-center justify-center">
              <div className="self-stretch relative leading-[30px] overflow-hidden whitespace-nowrap font-semibold">
              {serviceName}
              </div>
              <div className="self-stretch relative text-base leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap">
              {fullName}
              </div>
            </div>
          </div>

          <div className={`flex relative leading-[24px] text-sm justify-center items-center p-2 rounded-xl text-white ${bgColorClass}`}>
            {booking.bookingStatus === 0 ? "Open" :
          booking.bookingStatus === 1 ? "Pending" :
          booking.bookingStatus === 2 ? "Booked" :
          booking.bookingStatus === 3 ? "Confirmed" :
          booking.bookingStatus === 4 ? "Rejected" :
          booking.bookingStatus === 5 ? "Cancelled" :
          booking.bookingStatus === 6 ? "On The Fly (OTF)" :
          "Unknown Status"}
          </div>
          
        </div>

        <div className="self-stretch flex flex-row items-center justify-between text-sm">
        <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0"
              alt="Mode"
              src="/timelapse.svg"
            />
            <div className="relative leading-[22px]">
              {durationInMinutes + " min"}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0"
              alt="Mode"
              src="/icons-1.svg"
            />
            <div className="relative leading-[22px]">
              {bookingType === 0 ? "Online" : "On-Site"}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-end justify-end text-end pt-2 text-sm text-vendor-colors-monochromatic font-heading-h5">
      </div>
    </div>
  );
};

AppointmentCardConsumer.propTypes = {
  booking: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    bookingStartTime: PropTypes.string.isRequired,
    bookingEndTime: PropTypes.string.isRequired,
    bookingType: PropTypes.number.isRequired,
  }).isRequired,

};

export default AppointmentCardConsumer;