import Loader from "../SubComponent/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedPractitioners } from "../../Actions/Consumer/GetFeaturedPractitonerAction";
import CMPBusinessCard from "../SubComponent/ButtonBusinessCard";
import { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Button as BaseButton, buttonClasses } from "@mui/base/Button";
import { useNavigate } from "react-router-dom";
import { all } from "axios";

const SectionExploreBestPractitio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allPractitioners = useSelector(
    (state) => state.getPractitioners.getPractitioners
  );
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 7;
  const scrollContainerRef = useRef(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  useEffect(() => {
    dispatch(getFeaturedPractitioners(pageNumber, pageSize));
  }, [dispatch, pageNumber]);

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: 1500,
        behavior: "smooth",
      });
      setIsLeftDisabled(false);
      setIsRightDisabled(true);
    }
  };
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: -1500,
        behavior: "smooth",
      });
      setIsLeftDisabled(true);
      setIsRightDisabled(false);
    }
  };
  const handleSeeMore = () => {
    navigate("/search-results-practitioners");
  };
  const handleCardClick = (id) => {
    navigate("/practitioner-details", { state: { id } });
  };

  return (
    <section className="flex flex-col items-start justify-start py-8 px-[100px] box-border gap-[10px] max-w-[1440px] text-left text-13xl text-character-title-85 font-web-primary-h3-primary-dm-sans mq750:py-9 mq750:px-[50px] mq750:box-border mq450:pl-5 mq450:pr-5 mq450:box-border">
      <h2 className="flex flex-row relative text-inherit leading-[48px] font-bold font-inherit inline-block max-w-[1440px] mq750:text-7xl mq750:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
        Explore Best Practitioners
        <div className="ml-[750px]">
          <IconButton onClick={scrollLeft} disabled={isLeftDisabled}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={scrollRight} disabled={isRightDisabled}>
            <ArrowForwardIcon />
          </IconButton>

        </div>
      </h2>
      <div
        ref={scrollContainerRef}
        className="flex flex-row items-start overflow-x-auto gap-[0px] pt-2 h-min-[350px] max-w-[1240px] min-w-[600px] text-xl text-neutral-10 mq750:gap-[16px]"
        style={{
          scrollBehavior: "smooth",
          overflowX: "hidden",
          height: `350px`,
        }}
      >
        {allPractitioners === "isLoading" ? (
          <Loader />
        ) : (
          <>
            {allPractitioners.map((practitioner) => (
              <div
              key={practitioner.id}
                className="rounded-xl cursor-pointer m-2"
                onClick={() => handleCardClick(practitioner.id)}
              >
                <CMPBusinessCard key={practitioner.id} data={practitioner} />
              </div>
            ))}
            {allPractitioners.length > 4 &&
              <div className="rounded-xl cursor-pointer m-2">
                <div className="max-w-[270px] max-h-[320px] min-h-[320px] min-w-[270px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.3),_0px_1px_3px_1px_rgba(0,_0,_0,_0.15)] rounded-xl bg-gray-100 shrink-0 flex flex-col items-center justify-center text-left text-xl text-neutral-10 font-web-primary-h3-primary-dm-sans" onClick={handleSeeMore}>
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="w-full h-full flex flex-row items-center justify-center p-4">
                      <div className="w-full text-center overflow-hidden">
                        See More
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </section>
  );
};

export default SectionExploreBestPractitio;
