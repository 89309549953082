import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSchedules, resetUpdateSchedules } from "../../Actions/Practitioner/updateScheduleAction";
import { setSelection } from "../../Actions/Practitioner/addServiceAction";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../SubComponent/Loader";
import { Axios } from "axios";
import { BASE_URL } from "../../Constants";
import { Schedule } from "@mui/icons-material";


const generateTimeOptions = () => {
  const times = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  for (let i = 0; i <= 47; i++) {
    const time = new Date(start);
    time.setMinutes(start.getMinutes() + i * 30);
    times.push(time);
  }

  return times;
};

const timeOptions = generateTimeOptions();

const formatTime12h = (date) => format(date, "hh:mm a");

const EditSchedule = () => {
  const selectedSchedule = useSelector((state) => state.getSchedules.selection);
  const updateSchedule = useSelector((state) => state.updateSchedule.updateSchedule);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [toggleWeek, setToggleWeek] = useState("week1");
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scheduleSlots, setScheduleSlots] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const id = UserData.user.id;
  const handleDateChange = (date) => {
    setErrorMessage("");
    setSelectedDate(date);
    setEndDate(addDays(date, 13));
  };

  const handleToggle = (event, newToggle) => {
    setToggleWeek(newToggle);
  };

  const getWeekDates = (startDate, weekNumber) => {
    const start = addDays(startDate, weekNumber === "week2" ? 7 : 0);
    return Array.from({ length: 7 }, (_, i) => addDays(start, i));
  };

  const handleAddSlot = (day) => {
    const newSlot = { start: null, end: null };
    setScheduleSlots((prev) => ({
      ...prev,
      [day]: [...(prev[day] || []), newSlot],
    }));
  };

  const handleRemoveSlot = (day, index) => {
     if(scheduleSlots[day][index].slotId)
      {
        const slotId = scheduleSlots[day][index].slotId;
        try {
          const response =  axios.delete( BASE_URL + "PractitionerSchedule/DeleteSlot", {
            params: { slotId },
            headers: { TimeZoneOffSet: ((new Date().getTimezoneOffset() /60).toString()) },
          });
            toast.success("Slot deleted successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          
        } catch (error) {
          console.error("Error deleting slot:", error);
          toast.error("Failed to delete slot. Please try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } 
      
    setScheduleSlots((prev) => ({
      ...prev,
      [day]: prev[day].filter((_, i) => i !== index),
    }));
  };

  const handleSlotChange = (day, index, key, value) => {
    setScheduleSlots((prev) => {
      const updatedSlots = prev[day].map((slot, i) => {
        if (i === index) {
          return { ...slot, [key]: value };
        }
        return slot;
      });

      const startTime = updatedSlots[index].start;
      const endTime = updatedSlots[index].end;

      if (startTime && endTime && endTime <= startTime) {
        setErrorMessage("End time must be after the start time.");
        return prev;
      }

      const overlapping = updatedSlots.some((slot, i) => {
        if (i !== index && slot.start && slot.end) {
          return (
            (startTime >= slot.start && startTime < slot.end) ||
            (endTime > slot.start && endTime <= slot.end)
          );
        }
        return false;
      });

      if (overlapping) {
        setErrorMessage("There is already a slot for this time");
        return prev;
      }

      setErrorMessage("");
      return { ...prev, [day]: updatedSlots };
    });
  };

  const handleDaySelect = (date) => {
    setSelectedDay(date);
    
  };

  const weekDates = selectedDate ? getWeekDates(selectedDate, toggleWeek) : [];
  const selectedDayFormatted = selectedDay
    ? format(selectedDay, "yyyy-MM-dd")
    : "";

    const handleSave = () => {
      const startYear = selectedDate.getFullYear();
      const requestData = {
        userId: id,
        scheduleId: selectedSchedule.scheduleId,
        updateScheduleSlotRequests: Object.entries(scheduleSlots).map(([day, slots]) => {
          const slotDate = new Date(day);
          slotDate.setFullYear(startYear);
    
          const validSlots = slots
            .filter(slot => 
              (slot.bookingStatus === "Open" || slot.bookingStatus == null) && 
              (slot.appointmentStatus === "Open" || slot.appointmentStatus == null)
            )
            .map(slot => ({
              slotId: slot.slotId ? slot.slotId : null,
              slotStart: format(slot.start, "HH:mm:ss"),
              slotEnd: format(slot.end, "HH:mm:ss"),
              status: slot.status ? slot.status : 0,
              bookingStatus: slot.bookingStatus ? slot.bookingStatus : null,
              active: true,
            }));
  
          return validSlots.length > 0 ? {
            slotDate: slotDate,
            slotTimes: validSlots,
          } : null;
        }).filter(request => request !== null), 
      };
    
      dispatch(UpdateSchedules(requestData));
    };
    

  const addSuccess = () => {
    toast.success("Schedule updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let num = 1;
    dispatch(setSelection(num));
    dispatch(resetUpdateSchedules());
  };

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let num = 1;
    dispatch(setSelection(num));
    dispatch(resetUpdateSchedules());
  };
 
  const handleCancel = () => {
    let num = 1;
    dispatch(setSelection(num));
  };
 
  
  const getFilteredEndTimes = (startTime) => {
    if (!startTime) return timeOptions;
    const startDate = new Date(startTime);
    return timeOptions.filter((time) => {
      const timeDate = new Date(time);
      const duration = (timeDate - startDate) / (1000 * 60);
      return duration >= 30 ;
    });
  };
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0);

  const getFilteredStartTimes = () => {
    const currentTime = new Date();
    const filteredTimes = [];


        filteredTimes.push(...timeOptions);
    

    return filteredTimes;
};

const getFilteredStartTimesExisting = (slotTime) => {
  const currentDate = new Date();
  currentDate.setHours(0,0,0,0);
  const day = selectedDay;
  day.setHours(0,0,0,0);
  const currentTime = new Date();
  const filteredTimes = [];
 
  if (day.getTime() === currentDate.getTime()) {
      const oneHourAhead = new Date(currentTime.getTime() + 60 * 60 * 1000);

      for (const time of timeOptions) {
          if (time >= oneHourAhead) {
              filteredTimes.push(time);
          }
      }
      if(slotTime != null)
        {
      filteredTimes.push(slotTime)
        }
  } else {

      filteredTimes.push(...timeOptions);
  }

  return filteredTimes;
};




  useEffect(() => {
    if (selectedSchedule) {
        const startDate = new Date(selectedSchedule.scheduleStartDate.replace('Z', ''));
        setSelectedDate(startDate);
        const endDate = new Date(selectedSchedule.scheduleEndDate.replace('Z', ''));
        setEndDate(endDate);

        const slots = selectedSchedule.updateScheduleSlotRequests.reduce(
            (acc, curr) => {
              const date = curr.slotDate;
              acc[date] = curr.slotTimes.map((slot) => {
                const startString = slot.slotStart;
                const time = new Date(`2000-01-01T${startString}`);
                const formattedStartTime = format(time, "hh:mm a");
                const startTime = timeOptions.find(
                    (time) => format(time, "hh:mm a") === formattedStartTime
                  );
                
                  const endString = slot.slotEnd;
                  const Endtime = new Date(`2000-01-01T${endString}`);
                  const formattedEndTime = format(Endtime, "hh:mm a");
                  const endTime = timeOptions.find(
                      (time) => format(time, "hh:mm a") === formattedEndTime
                    );  
                return {
                  start: startTime,
                  end: endTime,
                  slotId: slot.slotId,
                  status: slot.status,
                  bookingStatus: slot.bookingStatus,
                  appointmentStatus: slot.appointmentStatus
                };
              });
              return acc;
            },
            {}
          );
          setScheduleSlots(slots);
    }
  }, []);


  return (
    <div className="min-h-[600px] max-h-auto self-stretch flex flex-col items-end justify-start gap-[50px] max-w-full min-w-full text-left text-gray-800 font-sans mq750:gap-[20px]">
      <div className="self-stretch flex flex-row items-start justify-start max-w-full text-13xl text-neutral-10-1000">
        <div className="w-full flex flex-col items-start justify-start gap-[8px] max-w-full">
          <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
            Update Schedule
          </h1>
          <div className="self-stretch relative text-sm leading-[22px] min-w-full">
          You can add schedule upto 2 weeks which will be renewed after your confirmation at the end of the current schedule.
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="min-w-[400px]"
            label="Week 1 starting from"
            value={selectedDate}
            disabled
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorMessage}
                helperText={errorMessage}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className="min-w-[400px]"
            label="Week 2 ending at"
            value={endDate}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full flex justify-end">
        <ToggleButtonGroup
          className="min-w-[400px]"
          value={toggleWeek}
          exclusive
          onChange={handleToggle}
          aria-label="week toggle"
        >
          <ToggleButton
            value="week1"
            aria-label="week 1"
            className="min-w-[200px] rounded-full"
          >
            Week 1
          </ToggleButton>
          <ToggleButton
            value="week2"
            aria-label="week 2"
            className="min-w-[200px] rounded-full"
          >
            Week 2
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="w-full flex flex-wrap gap-[10px] mt-4">
        {weekDates.map((date, index) => (
          <div key={index} className="flex flex-col items-center">
            <Button
              variant="contained"
              disabled = {date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)}
              sx={{
                minWidth: "140px",
                borderRadius: "50px",
                boxShadow: "none",
                backgroundColor:
                  selectedDay?.getTime() === date.getTime()
                    ? "#006CF0"
                    : "#EAF2FF",
                color:
                  selectedDay?.getTime() === date.getTime() ? "white" : "black",
                "&:hover": {
                  backgroundColor:
                    selectedDay?.getTime() === date.getTime()
                      ? "#006CF0"
                      : "#EAF2FF",
                  color:
                    selectedDay?.getTime() === date.getTime()
                      ? "white"
                      : "black",
                },
              }}
              onClick={() => handleDaySelect(date)}
            >
              {format(date, "EEEE")}
              <br />
              {format(date, "MMMM d")}
            </Button>
          </div>
        ))}
      </div>
      {selectedDay && (
        <div className="w-full mt-4">
          <div className="flex items-center justify-between w-full">
            <Typography variant="h6">Availability hours</Typography>
            <Button
              onClick={() => handleAddSlot(selectedDayFormatted)}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            />
          </div>
          <div className="flex flex-col gap-4 mt-4 justify-between items-start w-full">
            {scheduleSlots[selectedDayFormatted]?.map((slot, index) => (
              <div key={index} className="flex items-center w-full gap-2">
                <FormControl className="w-[400px]">
                  <InputLabel id={`start-time-label-${index}`}>Start Time</InputLabel>
                  <Select
                  disabled = {slot.bookingStatus == "Pending" || slot.bookingStatus == "Booked" || slot.appointmentStatus == "Pending" || slot.appointmentStatus == "Booked" || ((selectedDay.toDateString() === currentDate.toDateString()) && (selectedDay.getMinutes() === currentDate.getMinutes()) && slot.start? new Date(slot.start) < new Date(): false)}
                    labelId={`start-time-label-${index}`}
                    value={slot.start || ""}
                    input={<OutlinedInput label="Start time" />}
                    onChange={(event) =>
                      handleSlotChange(
                        selectedDayFormatted,
                        index,
                        "start",
                        event.target.value
                      )
                    }
                  >
                    
                    {selectedDay.toDateString() === currentDate.toDateString()?
                    getFilteredStartTimesExisting(slot.start? slot.start : null).map(
                      (time) => (
                        <MenuItem key={time} value={time}>
                          {formatTime12h(time)}
                        </MenuItem>
                      )
                    ):
                    getFilteredStartTimes(scheduleSlots[selectedDayFormatted], index).map(
                      (time) => (
                        <MenuItem key={time} value={time}>
                          {formatTime12h(time)}
                        </MenuItem>
                      )
                    )
                    }
                  </Select>
                </FormControl>
                <FormControl className="w-[400px]">
                  <InputLabel id={`end-time-label-${index}`}>End Time</InputLabel>
                  <Select
                    labelId={`end-time-label-${index}`}
                    value={slot.end || ""}
                    input={<OutlinedInput label="End time" />}
                    onChange={(event) =>
                      handleSlotChange(
                        selectedDayFormatted,
                        index,
                        "end",
                        event.target.value
                      )
                    }
                    disabled={slot.bookingStatus == "Pending" || slot.bookingStatus == "Booked" || slot.appointmentStatus == "Pending" || slot.appointmentStatus == "Booked" || !slot.start || (selectedDay.toDateString() === currentDate.toDateString()) && (selectedDay.getMinutes() === currentDate.getMinutes()) && slot.start? new Date(slot.start) < new Date(): false}
                    
                  >
                    {slot.start &&
                      getFilteredEndTimes(slot.start).map((time) => (
                        <MenuItem key={time} value={time}>
                          {formatTime12h(time)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => handleRemoveSlot(selectedDayFormatted, index)}
                >
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="w-full flex items-center">
        {errorMessage && (
          <Typography color="error" variant="body2" className="mt-2">
            {errorMessage}
          </Typography>
        )}
      </div>
      <div className="w-[354px] h-[100px] flex flex-row items-start justify-start py-0 pr-0 pt-12 pl-5 box-border gap-[14px] max-w-full">
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "18",
            background: "#fff",
            border: "#d9d9d9 solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#fff" },
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="self-stretch flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "18",
            background: "#006a6a",
            border: "#006a6a solid 1px",
            borderRadius: "4px",
            "&:hover": { background: "#006a6a" },
          }}
          onClick={handleSave}
        >
          {updateSchedule === "isLoading" ? (
            <div>
              <Loader />
            </div>
          ) : updateSchedule.status === true ? (
            addSuccess()
          ) : updateSchedule.status === false ? (
            showError(updateSchedule.message)
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};

export default EditSchedule;