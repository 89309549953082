
import {UPDATE_PROFILE_FAIL, UPDATE_PROFILE_PENDING, UPDATE_PROFILE_SUCCESS, RESET_UPDATE_PROFILE} from "../../Actions/Types";
    const initialState = {
        updateProfile: "",
    };
    const updateProfileReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case UPDATE_PROFILE_SUCCESS:
          return {
            ...state,
            updateProfile: payload.updateProfile,
          };
        case UPDATE_PROFILE_PENDING:
          return {
            ...state,
            updateProfile: payload.updateProfile,
          };
        case UPDATE_PROFILE_FAIL:
        return {
          ...state,
        };
        case RESET_UPDATE_PROFILE:
      return {
        ...state,
        updateProfile: initialState.updateProfile, 
      };
        default:
          return state;
      }
    };
    export default updateProfileReducer;