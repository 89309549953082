
import {ADD_SERVICE_FAIL, ADD_SERVICE_PENDING, RESET_ADD_SERVICE, ADD_SERVICE_SUCCESS, SELECTION_SUCCESS, SERVICE_DATA} from "../../Actions/Types";
    const initialState = {
        addService: "",
        selection: 0,
        srvData: []
    };
    const addServiceReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case ADD_SERVICE_SUCCESS:
          return {
            ...state,
            addService: payload.addService,
          };
        case ADD_SERVICE_PENDING:
          return {
            ...state,
            addService: payload.addService,
          };
        case ADD_SERVICE_FAIL:
        return {
          ...state,
        };
        case RESET_ADD_SERVICE:
      return {
        ...state,
        addService: "", 
      };
      case SELECTION_SUCCESS:
          return {
            ...state,
            selection: payload.selection,
          };
          case SERVICE_DATA:
          return {
            ...state,
            srvData: payload.srvData,
          };
        default:
          return state;
      }
    };
    export default addServiceReducer;