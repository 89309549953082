
import { GET_CONSUMER_BOOKING_FAIL, GET_CONSUMER_BOOKING_PENDING, GET_CONSUMER_BOOKING_SUCCESS, GET_CONSUMER_HISTORY_BOOKING_FAIL, GET_CONSUMER_HISTORY_BOOKING_PENDING, GET_CONSUMER_HISTORY_BOOKING_SUCCESS, GET_CONSUMER_UPCOMING_BOOKING_SUCCESS, GET_CONSUMER_UPCOMING_BOOKING_PENDING, GET_CONSUMER_UPCOMING_BOOKING_FAIL, GET_CONSUMER_PENDING_BOOKING_SUCCESS, GET_CONSUMER_PENDING_BOOKING_PENDING, GET_CONSUMER_PENDING_BOOKING_FAIL } from "../../Actions/Types"
const initialState = {
  getConsumerBooking: [],
  getConsumerHistoryBooking: [],
  getConsumerUpcomingBooking: [],
  getConsumerPendingBooking: [],
};
const getConsumerBookingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONSUMER_BOOKING_SUCCESS:
      return {
        ...state,
        getConsumerBooking: payload.getConsumerBooking,
      };
    case GET_CONSUMER_BOOKING_PENDING:
      return {
        ...state,
        getConsumerBooking: payload.getConsumerBooking,
      };
    case GET_CONSUMER_BOOKING_FAIL:
      return {
        ...state,
      };
    case GET_CONSUMER_HISTORY_BOOKING_SUCCESS:
      return {
        ...state,
        getConsumerHistoryBooking: payload.getConsumerHistoryBooking,
      };
    case GET_CONSUMER_HISTORY_BOOKING_PENDING:
      return {
        ...state,
        getConsumerHistoryBooking: payload.getConsumerHistoryBooking,
      };
    case GET_CONSUMER_HISTORY_BOOKING_FAIL:
      return {
        ...state,
      };
    case GET_CONSUMER_PENDING_BOOKING_SUCCESS:
      return {
        ...state,
        getConsumerPendingBooking: payload.getConsumerPendingBooking,
      };
    case GET_CONSUMER_PENDING_BOOKING_PENDING:
      return {
        ...state,
        getConsumerPendingBooking: payload.getConsumerPendingBooking,
      };
    case GET_CONSUMER_PENDING_BOOKING_FAIL:
      return {
        ...state,
      };
    case GET_CONSUMER_UPCOMING_BOOKING_SUCCESS:
      return {
        ...state,
        getConsumerUpcomingBooking: payload.getConsumerUpcomingBooking,
      };
    case GET_CONSUMER_UPCOMING_BOOKING_PENDING:
      return {
        ...state,
        getConsumerUpcomingBooking: payload.getConsumerUpcomingBooking,
      };
    case GET_CONSUMER_UPCOMING_BOOKING_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default getConsumerBookingReducer;