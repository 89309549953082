
import {ADD_BOOKING_FAIL, ADD_BOOKING_PENDING, ADD_BOOKING_SUCCESS, RESET_ADD_BOOKING} from "../../Actions/Types";
    const initialState = {
        addBooking: "",
    };
    const addBookingReducer = (state = initialState, action) => {
      const { type, payload } = action;
      switch (type) {
        case ADD_BOOKING_SUCCESS:
          return {
            ...state,
            addBooking: payload.addBooking,
          };
        case ADD_BOOKING_PENDING:
          return {
            ...state,
            addBooking: payload.addBooking,
          };
        case ADD_BOOKING_FAIL:
        return {
          ...state,
        };
        case RESET_ADD_BOOKING:
      return {
        ...state,
        addBooking: "", 
      };
        default:
          return state;
      }
    };
    export default addBookingReducer;