import {
  GET_CONSUMER_BOOKING_FAIL, GET_CONSUMER_BOOKING_PENDING, GET_CONSUMER_BOOKING_SUCCESS, GET_CONSUMER_HISTORY_BOOKING_FAIL, GET_CONSUMER_HISTORY_BOOKING_PENDING, GET_CONSUMER_HISTORY_BOOKING_SUCCESS,
  GET_CONSUMER_UPCOMING_BOOKING_SUCCESS, GET_CONSUMER_UPCOMING_BOOKING_PENDING, GET_CONSUMER_UPCOMING_BOOKING_FAIL, GET_CONSUMER_PENDING_BOOKING_SUCCESS, GET_CONSUMER_PENDING_BOOKING_PENDING, GET_CONSUMER_PENDING_BOOKING_FAIL, SET_MESSAGE
} from "../Types"
import ConumerService from "../../services/ConumerService";
import { toast } from "react-toastify"


export const getConsumerBooking = (consumerId) => async (dispatch) => {
  dispatch({
    type: GET_CONSUMER_BOOKING_PENDING,
    payload: { getConsumerBooking: "isLoading" },
  });
  return await ConumerService.getConsumerPendingAndUpcomingBookings(consumerId)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_CONSUMER_BOOKING_SUCCESS,
            payload: { getConsumerBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_CONSUMER_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_CONSUMER_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};


export const getConsumerHistoryBooking = (consumerId) => async (dispatch) => {
  dispatch({
    type: GET_CONSUMER_HISTORY_BOOKING_PENDING,
    payload: { getConsumerHistoryBooking: "isLoading" },
  });
  return await ConumerService.getConsumerHistoryBookings(consumerId)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_CONSUMER_HISTORY_BOOKING_SUCCESS,
            payload: { getConsumerHistoryBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_CONSUMER_HISTORY_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_CONSUMER_HISTORY_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getConsumerUpcomingBooking = (consumerId) => async (dispatch) => {
  dispatch({
    type: GET_CONSUMER_UPCOMING_BOOKING_PENDING,
    payload: { getConsumerUpcomingBooking: "isLoading" },
  });
  return await ConumerService.getConsumerUpcomingBookings(consumerId)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_CONSUMER_UPCOMING_BOOKING_SUCCESS,
            payload: { getConsumerUpcomingBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_CONSUMER_UPCOMING_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_CONSUMER_UPCOMING_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const getConsumerPendingBooking = (consumerId) => async (dispatch) => {
  dispatch({
    type: GET_CONSUMER_PENDING_BOOKING_PENDING,
    payload: { getConsumerPendingBooking: "isLoading" },
  });
  return await ConumerService.getConsumerPendingBookings(consumerId)
    .then(
      (data) => {
        console.log(data);
        if (data.status == 200) {
          dispatch({
            type: GET_CONSUMER_PENDING_BOOKING_SUCCESS,
            payload: { getConsumerPendingBooking: data.data },
          })
        }
        else {
          dispatch({
            type: GET_CONSUMER_PENDING_BOOKING_FAIL,
          });
          toast.error(data.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("error ", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: GET_CONSUMER_PENDING_BOOKING_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
