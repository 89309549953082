import axios from "axios";
import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';
import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;
class GetPractitionerBooking {
    async getPractitionerUpcomingBooking(practitionerId) {
        interceptor();
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.get(API_URL + "PractitionerSchedule/GetPractitionerUpcomingBooking", {
          params: {
            Id: practitionerId,
          },
          headers: {
            ...authHeader(),
            TimeZoneOffSet: timeZoneOffset,
          },
        });
      }

      async getPractitionerHistoryBooking(practitionerId) {
        interceptor();
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.get(API_URL + "PractitionerSchedule/GetPractitionerHistoryBooking", {
          params: { Id: practitionerId },
          headers: {
            ...authHeader(),
            TimeZoneOffSet: timeZoneOffset,
          },
        });
      }
    

      async getPractitionerBookings(practitionerId) {
        interceptor();
        const timeZoneOffset = ((new Date().getTimezoneOffset().toString())/60) * -1;
        return axios.get(API_URL + "PractitionerSchedule/GetPractitionerBookings", {
          params: { Id: practitionerId },
          headers: {
            ...authHeader(),
            TimeZoneOffSet: timeZoneOffset,
          },
        });
      }


}

export default new GetPractitionerBooking();

