import { Button } from "@mui/material";
import PractitionerHeader from "../components/Layout/PractitionerHeader";
import PractitionerFooter from "../components/Layout/PractitionerFooter";
import { useNavigate } from "react-router-dom";


const PractitionerLandingPage = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("user"));
  const NavigateToSignup = () => {
    navigate('/practitioner-sign-up')
  }
  return (
    
    <div className="w-full relative bg-gray-100 overflow-hidden flex flex-col mx-[auto] items-start justify-start leading-[normal] tracking-[normal] text-left text-smi text-vendor-colors-triadic font-web-primary-h3-primary-dm-sans">
      <PractitionerHeader></PractitionerHeader>
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-14 box-border max-w-full text-left text-smi text-vendor-colors-triadic font-web-primary-h3-primary-dm-sans mq750:pb-9 mq750:box-border">
        <div className="flex-1 bg-ghostwhite-100 flex flex-col items-center justify-start py-14 px-5 box-border max-w-full mq750:pt-9 mq750:pb-9 mq750:box-border">
          <div className="w-[1240px] flex flex-row items-start justify-between max-w-full gap-[20px] mq1225:flex-wrap">
            <div className="w-[664px] overflow-hidden shrink-0 flex flex-col items-start justify-center py-[72.5px] px-0 box-border gap-[24px] min-w-[664px] max-w-full mq750:pt-[47px] mq750:pb-[47px] mq750:box-border mq750:min-w-full mq1225:flex-1">
              <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
                <b className="relative tracking-[1.56px] uppercase">
                  Let’s shift your business
                </b>
                <h1 className="m-0 self-stretch relative text-41xl tracking-[-2px] leading-[65px] font-bold font-inherit text-gray-200 mq450:text-17xl mq450:leading-[39px] mq750:text-29xl mq750:leading-[52px]">
                  Empower your business with The Healers.
                </h1>
              </div>
              <div className="w-[566px] relative text-lgi tracking-[-0.2px] leading-[32px] text-gray-700 inline-block mix-blend-normal max-w-full">
                Unlock Your Healing Potential, Connect with Clients, and
                Flourish in Your Practice with The Healers App – Your Trusted
                Partner in Holistic Health and Wellness.
              </div>
              {(!UserData  || UserData && UserData.user.role !== 1) && (
                <Button
                className="w-[233px] h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#006a6a",
                  border: "#006a6a solid 1px",
                  borderRadius: "4px",
                  "&:hover": { background: "#006a6a" },
                  width: 233,
                  height: 60,
                }}
                onClick={NavigateToSignup}
              >
                Join as Practitioner
              </Button>
              )}
              
            </div>
            <img
              className="w-[463px] relative max-h-full object-cover max-w-full mq1225:flex-1"
              loading="lazy"
              alt=""
              src="/divright-content@2x.png"
            />
          </div>
        </div>
      </section>
      <div className="w-[46%] flex flex-row items-start justify-center pt-0 px-5 pb-[21px] box-border max-w-full">
        <div className="w-[515px] flex flex-col items-start justify-start gap-[24px] max-w-full  ml-auto">
          <b className="relative tracking-[1.56px] uppercase">
            KNow more about us
          </b>
          <h1 className="m-0 self-stretch relative text-17xl tracking-[-1.2px] leading-[48px] font-bold font-inherit text-gray-200 mq450:text-3xl mq450:leading-[29px] mq750:text-10xl mq750:leading-[38px]">
            We're Here to Help You Grow Your Healing Practice: Explore The
            Healers Marketplace
          </h1>
        </div>
      </div>
      <section className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-14 box-border max-w-full text-left text-lg text-gray-700 font-web-primary-h3-primary-dm-sans mq750:pb-9 mq750:box-border">
        <div className="w-[1110px] flex flex-row items-end justify-center gap-[95px] max-w-full mq750:gap-[24px] mq1050:flex-wrap mq1225:gap-[47px]">
          <img
            className="w-[445px] relative rounded-3xs max-h-full object-cover max-w-full mq1050:flex-1"
            loading="lazy"
            alt=""
            src="/img@2x.png"
          />
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[26px] box-border min-w-[370px] max-w-full mq450:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[100px] max-w-full mq450:gap-[25px] mq750:gap-[50px]">
              <img
                className="self-stretch h-[377px] relative max-w-full overflow-hidden shrink-0 object-cover"
                loading="lazy"
                alt=""
                src="/divimages@2x.png"
              />
              <div className="w-[490px] relative tracking-[-0.2px] leading-[29px] inline-block mix-blend-normal max-w-full">
                Welcome to The Healers Marketplace, where healing meets
                innovation. Our platform is a vibrant ecosystem connecting
                healers, practitioners, and seekers from around the globe.
                Whether you're a seasoned healer or just beginning your journey,
                our marketplace offers a diverse range of services and
                modalities to support your holistic well-being.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch flex flex-col items-center justify-center py-14 px-5 box-border gap-[56px] max-w-full text-center text-smi text-consumer-colors-monochromatic font-web-primary-h3-primary-dm-sans mq750:gap-[28px] mq750:pt-9 mq750:pb-9 mq750:box-border">
        <div className="w-[589px] overflow-hidden flex flex-col items-center justify-start gap-[24px] max-w-full">
          <b className="self-stretch relative tracking-[1.63px] uppercase">
            Why choose us
          </b>
          <h1 className="m-0 w-[521px] relative text-17xl tracking-[-1.2px] leading-[48px] font-bold font-inherit text-gray-200 inline-block max-w-full mq450:text-3xl mq450:leading-[29px] mq750:text-10xl mq750:leading-[38px]">
            People choose us because we serve the best for everyone
          </h1>
        </div>
        <div className="w-[1240px] flex flex-row flex-wrap items-start justify-center py-0 px-[147px] box-border gap-[56px] min-h-[236px] max-w-full text-left text-2xl text-gray-200 mq750:gap-[28px] mq750:pl-9 mq750:pr-9 mq750:box-border mq1225:pl-[73px] mq1225:pr-[73px] mq1225:box-border">
          <div className="flex flex-row items-center justify-start py-0 pr-[22px] pl-0 box-border gap-[32px] max-w-full mq450:gap-[16px] mq750:flex-wrap">
            <img
              className="h-20 w-20 relative"
              loading="lazy"
              alt=""
              src="/icon.svg"
            />
            <div className="flex flex-col items-start justify-start">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Global Reach, Local Connection
              </b>
              <div className="w-[300px] relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 inline-block mix-blend-normal">
                Expand globally, connect locally, reach diverse customers
                effortlessly
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[32px] max-w-full mq450:gap-[16px] mq750:flex-wrap">
            <img
              className="h-[79px] w-[79px] relative"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex flex-col items-start justify-start max-w-full">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Effortless Setup and Management
              </b>
              <div className="w-[300px] relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 inline-block mix-blend-normal">
                Streamlined setup, simple management, user-friendly.
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start py-0 pr-px pl-0 box-border gap-[32px] max-w-full mq450:gap-[16px] mq750:flex-wrap">
            <img
              className="h-[79px] w-[79px] relative"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex flex-col items-start justify-start max-w-full">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Marketing and Promotion Support
              </b>
              <div className="w-[300px] relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 inline-block mix-blend-normal">
                Boost sales, enhance visibility, targeted marketing for
                increased exposure.
              </div>
            </div>
          </div>
          <div className="w-[445px] flex flex-row items-center justify-start py-0 pr-[34px] pl-0 box-border gap-[32px] max-w-full mq450:gap-[16px] mq750:flex-wrap">
            <img
              className="h-[79px] w-[79px] relative"
              alt=""
              src="/icon-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-start min-w-[195px]">
              <b className="relative tracking-[-0.5px] leading-[32px] mq450:text-mid mq450:leading-[26px]">
                Actionable Insights for Growth
              </b>
              <div className="self-stretch relative text-mid tracking-[-0.2px] leading-[29px] text-gray-700 mix-blend-normal">
                Track trends, optimize strategy, data-driven decisions for
                business success.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="self-stretch bg-vendor-colors-primary-1 flex flex-row items-center justify-center py-14 px-[100px] box-border gap-[80px] max-w-full text-left text-smi text-consumer-colors-monochromatic font-web-primary-h3-primary-dm-sans mq450:gap-[20px] mq450:pl-5 mq450:pr-5 mq450:box-border mq750:gap-[40px] mq750:py-9 mq750:px-[50px] mq750:box-border mq1225:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-center gap-[8px] min-w-[461px] max-w-[708px] mq750:min-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
            <b className="w-[589px] relative tracking-[1.63px] uppercase inline-block max-w-full">
              The Healers app
            </b>
            <h1 className="m-0 self-stretch relative text-37xl leading-[110%] font-bold font-inherit text-blue-gray-900 mq450:text-15xl mq450:leading-[37px] mq750:text-26xl mq750:leading-[49px]">
              Optimize Your Healing Practices
            </h1>
          </div>
          <div className="self-stretch relative text-xl leading-[180%] text-blue-gray-900 mq450:text-base mq450:leading-[29px]">
            Maximize your potential as a practitioner with our dedicated mobile
            app. Streamline your operations, manage your products, and connect
            with customers—all from the convenience of your smartphone or
            tablet. Whether you're on the go or in your studio, our app empowers
            you to stay organized, responsive, and in control of your business.
          </div>
        </div>
        <div className="h-[575px] w-[451.1px] relative max-w-full flex items-center justify-center">
          <img
            className="h-full w-full object-contain absolute left-[0px] top-[24px] [transform:scale(1.168)] mq1225:flex-1"
            alt=""
            src="/divright-content-1@2x.png"
          />
        </div>
      </section>
      <PractitionerFooter></PractitionerFooter>
    </div>
  );
};

export default PractitionerLandingPage;
