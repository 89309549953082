import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "50px",
        height: "50px",
        margin: "0 auto"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          width: "30px",
          height: "30px",
          border: "3px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          borderTopColor: "#333",
          animation: "spin 2s infinite linear" 
        }}
      ></div>
    </div>
  );
};

export default Loader;