import React, { useState, useEffect } from 'react';
import {
  Button,
} from "@mui/material";
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { confirmSignUp, resetconfirmState } from '../Actions/Auth/ConfirmSignupAction';
import Loader from "../components/SubComponent/Loader";
import { resendOtp } from '../Actions/Auth/ResendOtpAction';

const ConsumerSignUpEmailVer = () => {
  useEffect(() => {
    dispatch(resetconfirmState());
  }, []);
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const formData = useSelector(state => state.signup.UserData);
  const confirmSignupState = useSelector(state => state.confirm_signup);
  const navigate = useNavigate();

  const handleChange = (otp) => {
    setOtp(otp);
  };
  const handleBackClick = () => {
    navigate("/consumer-sign-up");
  };
  const handleVerifyClick = async () => {
    const body = 
      {
        "email": formData.email,
        "verificationCode": otp,
        "password": formData.password,
        "userType": 0
      }
      dispatch(confirmSignUp(body));
  }
  const handleResendClick = async () => {
    dispatch(resendOtp(formData.email));
  }
  const confirmHandle = () => {
    localStorage.setItem("user",JSON.stringify(confirmSignupState.UserData));
    navigate("/consumer-preferences-01");
  };
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-row items-center justify-center py-20 pr-20 pl-[163px] box-border gap-[197px] tracking-[normal] leading-[normal] text-left text-smi text-m3-black font-web-primary-h3-primary-dm-sans mq750:gap-[98px] mq750:pl-[81px] mq750:pr-10 mq750:box-border mq450:gap-[49px] mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="h-[57.5px] w-[404px] hidden flex-col items-start justify-start max-w-full z-[0] font-poppins">
        <div className="w-10 flex flex-row items-start justify-start">
          <div className="h-5 flex-1 relative font-medium inline-block">
            Name
          </div>
        </div>
        <div className="self-stretch h-8 rounded-3xs box-border overflow-hidden shrink-0 border-[1px] border-solid border-gainsboro-200" />
      </div>
      <div className="w-[400px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[400px] max-w-full text-xs text-neutral-10 mq750:pt-[155px] mq750:box-border mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[32px] mq450:gap-[16px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-xl">
            <h2 className="m-0 relative text-inherit leading-[30px] font-bold font-inherit inline-block min-w-[116px] mq450:text-base mq450:leading-[24px]">
              Verify Email
            </h2>
            <div className="self-stretch relative text-base leading-[24px]">
              <span>{`A 6 digit OTP has been sent to `}</span>
              <b>{formData.email}</b>
              {". "}
              <span>Please verify to continue.</span>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-text-secondary mq450:flex-wrap">
            <div className="w-10 flex flex-row items-start justify-start">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                isInputNum
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  fontSize: "18px",
                  textAlign: "center",
                  outline: "none",
                }}
                containerStyle={{ display: "flex", gap: "20px" }}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end text-center">
            <div className="flex flex-row items-start justify-start gap-[2px]">
              <div className=" relative leading-[18px] mt-2">
                Didn’t receive the code?
              </div>
              <Button
                className="[text-decoration:underline] relative leading-[18px] text-mediumblue inline-block min-w-[21px] "
                onClick={handleResendClick}
              >
                Resend
              </Button>
            </div>
          </div>
          <Button
            className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
            variant="contained"
            disabled={!(otp.length == 6)}
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              borderRadius: "4px",
              "&:hover": { background: "#f28500" },
              height: 60,
            }}
            onClick={handleVerifyClick}
          >
            {confirmSignupState.confirmSignUp === "isLoading" ? (
              <div className="mt-2">
                <Loader></Loader>
              </div>
            ) : confirmSignupState.confirmSignUp.status &&
              confirmSignupState.confirmSignUp.status === true ? (
              confirmHandle()
            ) : (
              "Verify Email"
            )}
          </Button>
          <Button
            className="w-[167px] h-[42px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
            startIcon={
              <img width="16px" height="16px" src="/navigate-before.svg" />
            }
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#434343",
              fontSize: "14",
              background: "#fff",
              border: "#d9d9d9 solid 1px",
              borderRadius: "4px",
              "&:hover": { background: "#fff" },
              width: 167,
              height: 42,
            }}
            onClick={handleBackClick}
          >
            Back to Sign Up
          </Button>
        </div>
      </div>
      <div>
        <img src="/div-right content.svg" />
      </div>
    </div>
  );
};

export default ConsumerSignUpEmailVer;