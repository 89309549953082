import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppointmentCardPending from "../SubComponent/AppointmentCardPending";
import { useDispatch, useSelector } from "react-redux";
import { getBooking, getHistoryBooking, getUpcomingBooking } from "../../Actions/Practitioner/GetBookingAction";
import Loader from "../SubComponent/Loader";
import AppointmentCardHistory from "../SubComponent/AppointmentCardHistory";
import AppointmentCardUpcoming from "../SubComponent/AppointmentCardUpcoming";
import Menu from '@mui/material/Menu';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FilterButton from "../SubComponent/FilterButton";
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AppointmentsHeader = (services) => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const PractitionerId = UserData.user.id;
  const dispatch = useDispatch();

  const practitionerServices = services.services;

  const [updateCount, setUpdateCount] = useState(0);
  const [selectedUpcomingDateRange, setSelectedUpcomingDateRange] = useState("");
  const [selectedPendingDateRange, setSelectedPendingDateRange] = useState("");
  const [selectedHistoryDateRange, setSelectedHistoryDateRange] = useState("");
  const [filteredUpcomingBookings, setFilteredUpcomingBookings] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedUpcomingServices, setSelectedUpcomingServices] = useState([]);
  const [selectedPendingServices, setSelectedPendingServices] = useState([]);
  const [selectedHistoryServices, setSelectedHistoryServices] = useState([]);
  const [filteredHistoryBookings, setFilteredHistoryBookings] = useState([]);



  useEffect(() => {
    dispatch(getBooking(PractitionerId));
    dispatch(getHistoryBooking(PractitionerId));
    dispatch(getUpcomingBooking(PractitionerId));
  }, [dispatch, PractitionerId, updateCount]);

  const bookings = useSelector((state) => state.getBooking.getBooking);
  const upcomingBookings = useSelector((state) => state.getBooking.getUpcomingBooking);
  const historyBookings = useSelector((state) => state.getBooking.getHistoryBooking);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Function to handle successful booking update
  const handleBookingUpdate = () => {
    // Increment the update count to trigger re-fetching of data
    setUpdateCount((prev) => prev + 1);
  };
  //

  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const [durationAnchorEl, setDurationAnchorEl] = useState(null);
  const [serviceTypeAnchorEl, setServiceTypeAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);

  const handleOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleClose = (setter) => () => {
    setter(null);
  };
  useEffect(() => {
    filterUpcomingBookings(selectedUpcomingDateRange, selectedUpcomingServices);
  }, [upcomingBookings, selectedUpcomingDateRange, selectedUpcomingServices]);

  const filterUpcomingBookings = (dateRange, services) => {
    if (upcomingBookings === "isLoading") {
      setFilteredUpcomingBookings([]);
      return;
    }
    if (!upcomingBookings || upcomingBookings.length === 0) {
      setFilteredUpcomingBookings([]);
      return;
    }

    const now = new Date();
    let startDate, endDate;

    switch (dateRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(0, 0, 0, 0));
        break;
      case "This week":
        const firstDayOfWeek = now.getDate() - now.getDay();
        startDate = new Date(now.setDate(firstDayOfWeek));
        endDate = new Date(now.setDate(firstDayOfWeek + 6)); // End of the current week
        break;
      case "This month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // End of the current month
        break;
      default:
        startDate = null;
        endDate = null;
    }
    const filtered = upcomingBookings.filter((booking) => {
      const bookingDate = new Date(booking.bookingStartTime);
      return (
        (!startDate || bookingDate >= startDate) &&
        (!endDate || bookingDate <= endDate) &&
        (services.length === 0 || services.includes(booking.serviceName))
      );
    });

    setFilteredUpcomingBookings(filtered);
  };
  const handleUpcomingDateRangeChange = (event) => {
    setSelectedUpcomingDateRange(event.target.value);
  };
  const handleUpcomingServiceChange = (event) => {
    const value = event.target.value;
    setSelectedUpcomingServices((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };
  //pending filtering
  useEffect(() => {
    filterBookings(selectedPendingDateRange, selectedDuration, selectedServiceType, selectedPendingServices);
  }, [bookings, selectedPendingDateRange, selectedDuration, selectedServiceType, selectedPendingServices]);

  const filterBookings = (dateRange, duration, serviceType, services) => {
    if (bookings === "isLoading") {
      setFilteredBookings([]);
      return;
    }
    if (!bookings || bookings.length === 0) {
      setFilteredBookings([]);
      return;
    }

    const now = new Date();
    let startDate, endDate;

    switch (dateRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "This week":
        const firstDayOfWeek = now.getDate() - now.getDay();
        startDate = new Date(now.setDate(firstDayOfWeek));
        endDate = new Date(now.setDate(firstDayOfWeek + 6));
        break;
      case "This month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        startDate = null;
        endDate = null;
    }

    const filtered = bookings.filter((booking) => {
      const bookingDate = new Date(booking.bookingStartTime);
      const bookingDuration = (new Date(booking.bookingEndTime) - new Date(booking.bookingStartTime)) / 60000;

      return (
        (!startDate || bookingDate >= startDate) &&
        (!endDate || bookingDate <= endDate) &&
        (duration.length === 0 || duration.includes(bookingDuration)) &&
        (serviceType.length === 0 || serviceType.includes(booking.bookingType)) &&
        (services.length === 0 || services.includes(booking.serviceName))
      );
    });

    setFilteredBookings(filtered);
  };
  const handlePendingDateRangeChange = (event) => {
    setSelectedPendingDateRange(event.target.value);
  };

  const handleDurationChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedDuration((prev) =>
      prev.includes(value) ? prev.filter((d) => d !== value) : [...prev, value]
    );
  };

  const handleServiceTypeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedServiceType((prev) =>
      prev.includes(value) ? prev.filter((st) => st !== value) : [...prev, value]
    );
  };
  const handlePendingServiceChange = (event) => {
    const value = event.target.value;
    setSelectedPendingServices((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };

  // history filter
  useEffect(() => {
    filterHistoryBookings(selectedHistoryDateRange, selectedStatus, selectedHistoryServices);
  }, [historyBookings, selectedHistoryDateRange, selectedStatus, selectedHistoryServices]);

  const filterHistoryBookings = (dateRange, status, services) => {
    if (historyBookings === "isLoading") {
      return;
    }
    if (!historyBookings || historyBookings.length === 0) {
      setFilteredHistoryBookings([]);
      return;
    }

    const now = new Date();
    let startDate, endDate;

    switch (dateRange) {
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        endDate = new Date(now.setHours(23, 59, 59, 999));
        break;
      case "This week":
        const firstDayOfWeek = now.getDate() - now.getDay();
        startDate = new Date(now.setDate(firstDayOfWeek));
        endDate = new Date(now.setDate(firstDayOfWeek + 6));
        break;
      case "This month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        startDate = null;
        endDate = null;
    }

    const filtered = historyBookings.filter((booking) => {
      const bookingDate = new Date(booking.bookingStartTime);
      return (
        (!startDate || bookingDate >= startDate) &&
        (!endDate || bookingDate <= endDate) &&
        (status.length === 0 || status.includes(booking.bookingStatus)) &&
        (services.length === 0 || services.includes(booking.serviceName))
      );
    });

    setFilteredHistoryBookings(filtered);
  };
  const handleHistoryDateRangeChange = (event) => {
    setSelectedHistoryDateRange(event.target.value);
  };
  const handleStatusChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedStatus((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };
  const handleHistoryServiceChange = (event) => {
    const value = event.target.value;
    setSelectedHistoryServices((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };
  return (
    <div className={`items-start flex-row justify-start gap-[56px] w-full mq750:gap-[28px] `}>
      <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full text-13xl">
        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Appointments
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]">
          View your upcoming, pending, and history appointments
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex-2">
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="appointment tabs">
            <Tab label="Upcoming" />
            <Tab label="Pending" />
            <Tab label="History" />
          </Tabs>
        </div>
        <div className="flex-1 flex justify-end gap-[8px]">
          {tabIndex === 0 && (
            <>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedUpcomingDateRange}
                    onChange={handleUpcomingDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                  </RadioGroup>
                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                    {practitionerServices && practitionerServices !== null && practitionerServices.length !== 0 && practitionerServices.map((service) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={selectedUpcomingServices.includes(service.name)}
                            onChange={handleUpcomingServiceChange}
                            value={service.name}
                          />
                        }
                        label={service.name}
                      />
                    ))}
                  </FormGroup>
                </Menu>
              </div>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedPendingDateRange}
                    onChange={handlePendingDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                  </RadioGroup>
                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setDurationAnchorEl)} open={Boolean(durationAnchorEl)} icon={<TimelapseOutlinedIcon />} text="Duration"
                />
                <Menu
                  id="duration-menu"
                  anchorEl={durationAnchorEl}
                  open={Boolean(durationAnchorEl)}
                  onClose={handleClose(setDurationAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration.includes(30)}
                      onChange={handleDurationChange}
                      value={30}
                    />} label="30 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration.includes(60)}
                      onChange={handleDurationChange}
                      value={60}
                    />} label="60 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration.includes(90)}
                      onChange={handleDurationChange}
                      value={90}
                    />} label="90 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration.includes(120)}
                      onChange={handleDurationChange}
                      value={120}
                    />} label="120 min" />
                  </FormGroup>

                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServiceTypeAnchorEl)} open={Boolean(serviceTypeAnchorEl)} icon={<CategoryOutlinedIcon />} text="Service Type"
                />
                <Menu
                  id="serviceType-menu"
                  anchorEl={serviceTypeAnchorEl}
                  open={Boolean(serviceTypeAnchorEl)}
                  onClose={handleClose(setServiceTypeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedServiceType.includes(0)}
                      onChange={handleServiceTypeChange}
                      value={0}
                    />} label="Online" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedServiceType.includes(1)}
                      onChange={handleServiceTypeChange}
                      value={1}
                    />} label="On site" />
                  </FormGroup>
                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                    {practitionerServices && practitionerServices !== null && practitionerServices.length !== 0 && practitionerServices.map((service) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={selectedPendingServices.includes(service.name)}
                            onChange={handlePendingServiceChange}
                            value={service.name}
                          />
                        }
                        label={service.name}
                      />
                    ))}
                  </FormGroup>

                </Menu>
              </div>
            </>
          )}

          {tabIndex === 2 && (
            <>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedHistoryDateRange}
                    onChange={handleHistoryDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                  </RadioGroup>
                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setStatusAnchorEl)} open={Boolean(statusAnchorEl)} icon={<CheckCircleOutlineOutlinedIcon />} text="Status"
                />
                <Menu
                  id="status-menu"
                  anchorEl={statusAnchorEl}
                  open={Boolean(statusAnchorEl)}
                  onClose={handleClose(setStatusAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedStatus.includes(4)}
                      onChange={handleStatusChange}
                      value={4}
                    />} label="Completed" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedStatus.includes(5)}
                      onChange={handleStatusChange}
                      value={5}
                    />} label="Cancelled" />
                  </FormGroup>

                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                    {practitionerServices && practitionerServices !== null && practitionerServices.length !== 0 && practitionerServices.map((service) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={selectedHistoryServices.includes(service.name)}
                            onChange={handleHistoryServiceChange}
                            value={service.name}
                          />
                        }
                        label={service.name}
                      />
                    ))}
                  </FormGroup>

                </Menu>
              </div>
            </>
          )}

        </div>
      </div>
      <TabPanel value={tabIndex} index={0}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-[254px] pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {upcomingBookings == "isLoading" && <div><Loader></Loader></div>}
          {upcomingBookings && upcomingBookings != "isLoading" && filteredUpcomingBookings.length !== 0 &&
            filteredUpcomingBookings.map((booking) => (
              <AppointmentCardUpcoming
                key={booking.id}
                booking={booking}
                onBookingUpdate={handleBookingUpdate}
              />
            ))
          }
          {upcomingBookings && upcomingBookings !== "isLoading" && filteredUpcomingBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pl-0 box-border gap-[22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {bookings === "isLoading" && <div><Loader></Loader></div>}
          {bookings && bookings != "isLoading" && filteredBookings.length !== 0 &&
            filteredBookings.map((booking) => (
              <AppointmentCardPending
                key={booking.id}
                booking={booking}
                onBookingUpdate={handleBookingUpdate}
              />
            ))
          }
          {bookings && bookings !== "isLoading" && filteredBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-[254px] pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {historyBookings == "isLoading" && <div><Loader></Loader></div>}
          {historyBookings && historyBookings != "isLoading" && filteredHistoryBookings.length !== 0 &&
            filteredHistoryBookings.map((booking) => (
              <AppointmentCardHistory
                key={booking.id}
                booking={booking}
              />
            ))
          }
          {historyBookings && historyBookings !== "isLoading" && filteredHistoryBookings.length === 0 && <div>No bookings available</div>}
        </div>
      </TabPanel>
    </div>
  );
};

export default AppointmentsHeader;